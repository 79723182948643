import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import Input from '../../NewUI/Input';
import { geo } from '../../../utils/data';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLocations } from '../../../pages/Bayer/actions';
import SelectMulti from '../../UI/SelectMulti';
import MultiSelect from '../../NewUI/MultiSelect';

const ModalThankYouPage = ({ domain, push = false, openScript, build, setBuild }) => {
    const [optionsLocal, setOptionsLocal] = useState([]);

    const dispatch = useDispatch();

    const loadLocales = async () => {
        const resp = await dispatch(getLocations());
        if (resp) {
            const options = resp.data.map((item) => ({ label: item, value: item }));
            setOptionsLocal(options);
        }
    };
    const handleChangeInput = (value, key) => {
        setBuild({ ...build, [key]: value });
    };

    const handleChangeSelect = (item, id) => {
        setBuild({ ...build, [id]: item });
    };

    const showLink = () => {
        if (domain?.domain_data?.thank_you_page) {
            if (push) {
                if (domain?.domain_data?.thank_you_page?.ty) {
                    return `https://${domain.name}/${domain?.domain_data?.thank_you_page?.ty}`;
                }
                return null;
            }
        }
        if (domain?.domain_data?.success_page) {
            if (domain?.domain_data?.success_page?.success) {
                return `https://${domain.name}/${domain?.domain_data?.success_page?.success}`;
            }
            return null;
        }

        return null;
    };

    useEffect(() => {
        loadLocales();
    }, []);

    const valuesLocales = () => {
        const isTypeString = typeof build?.locales === 'string';
        if (isTypeString) return [];
        return build?.locales ?? [];
    };

    return (
        <div className="ThankYouPage">
            <div className="ThankYouPage-header">
                <div className="ThankYouPage-title">
                    {/* <h2> Генерация Thanks Page</h2> */}
                    <span>
                        {push ? 'Пуш' : 'Витрина'} {showLink() !== null ? ` - ${showLink()}` : null}
                    </span>
                </div>
            </div>
            <div className="ThankYouPage-content">
                <Row>
                    <Col xs={6}>
                        <Input
                            onChange={(value) => handleChangeInput(value, 'full_valid_number_code_conversion_ads')}
                            type="text"
                            id="full_valid_number_code_conversion_ads"
                            placeholder="Полный ADS код 'Валидный номер'"
                            // placeholder="11520506744/kk1FFVM-444UZEJTttfs2"
                            value={
                                build?.full_valid_number_code_conversion_ads ? build?.full_valid_number_code_conversion_ads : ''
                            }
                        />
                    </Col>
                    <Col xs={6}>
                        <Input
                            onChange={(value) => handleChangeInput(value, 'ga_code')}
                            type="text"
                            id="ga_code"
                            placeholder="GA Analytics код"
                            // placeholder="G-HEV4B0ZW44"
                            value={build?.ga_code ? build?.ga_code : ''}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Input
                            onChange={(value) => handleChangeInput(value, 'full_visit_code_conversion_ads')}
                            type="text"
                            id="full_visit_code_conversion_ads"
                            placeholder="Полный ADS код 'Посещение'"
                            // placeholder="11520506744/FeFFVM-444UZEJuxreYp"
                            value={build?.full_visit_code_conversion_ads ? build?.full_visit_code_conversion_ads : ''}
                        />
                    </Col>
                    <Col xs={6}>
                        <Input
                            onChange={(value) => handleChangeInput(value, 'short_code_conversion_ads')}
                            type="text"
                            id="short_code_conversion_ads"
                            placeholder={'Короткий ADS код'}
                            // placeholder="11520506744"
                            value={build?.short_code_conversion_ads ? build?.short_code_conversion_ads : ''}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Input
                            onChange={(e) => handleChangeSelect(e, 'geo')}
                            placeholder={'GEO'}
                            options={geo}
                            itemLabel={'label'}
                            itemValue={'value'}
                            value={build?.geo}
                        />
                    </Col>
                    <Col xs={6}>
                        <MultiSelect
                            onChange={(e) => handleChangeSelect(e, 'locales')}
                            options={optionsLocal}
                            values={valuesLocales()}
                            value={valuesLocales()}
                            title={'Язык'}
                            multi
                            placeholder={'Язык'}
                            itemLabel={'label'}
                            itemValue={'value'}
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12}>
                        <textarea
                            id="custom_code"
                            onChange={handleChangeInput}
                            placeholder="Кастомный код (вставляется перед закрытием Body)"
                            className="ThankYouPage-textarea"
                            value={build?.custom_code}
                        ></textarea>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ModalThankYouPage;
