export const sysadminpanel = [
    {
        id: 12,
        hp: 'Hosting',
        account: 'sergijalexandrenckoo',
        ip: '185.161.20.8',
        expirationDate: '23.01.24',
        monitoring: 'флаги чисто',
    },
    {
        id: 12,
        hp: 'Hosting',
        account: 'sergijalexandrenckoo',
        ip: '185.161.20.8',
        expirationDate: '23.01.24',
        monitoring: 'флаги чисто',
    },
    {
        id: 12,
        hp: 'Hosting',
        account: 'sergijalexandrenckoo',
        ip: '185.161.20.8',
        expirationDate: '23.01.24',
        monitoring: 'флаги чисто',
    },
];

export const geo = [
    { label: 'USA', value: 'USA' },
    { label: 'Europe', value: 'Europe' },
];

export const operationSystemOptions = [
    { label: 'Clear', value: '' },
    { label: 'MacOS', value: 'macOS' },
    { label: 'Windows', value: 'windows' },
];

export const listDomains = [
    { id: 12, name: 'homegard1.com', topic: 'Home&Garden', status: 'NEW' },
    { id: 13, name: 'homegard2.com', topic: 'Home&Garden', status: 'NEW' },
    { id: 14, name: 'homegard3.com', topic: 'Home&Garden', status: 'NEW' },
];

export const optionsGeo = [
    { label: 'NA', value: 'NA' },
    { label: 'EU', value: 'EU' },
    { label: 'APAC', value: 'APAC' },
    { label: 'LATAM', value: 'LATAM' },
];

export const optionsRole = [
    { id: 1, label: 'webmaster', value: 'webmaster' },
    { id: 2, label: 'sysadmin', value: 'sysadmin' },
    { id: 3, label: 'buyer', value: 'buyer' },
    { id: 4, label: 'farmer', value: 'farmer' },
    { id: 5, label: 'helper', value: 'helper' },
];
