const ArrowRightSvg = ({ ...props }) => {
    return (
        <div {...props}>
            <svg width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id="Vector"
                    d="M6 13C8.19385 11.2031 9.29004 10.1074 11 8C9.29004 5.89258 8.19385 4.79688 6 3"
                    stroke="#676C74"
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

export default ArrowRightSvg;
