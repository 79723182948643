import './WizardCreate.scss';
import Input from '../../../components/NewUI/Input';
import Button from '../../../components/NewUI/Button';
import { ReactComponent as Edit } from '../../../assets/images/newIcon/edit.svg';
import { ReactComponent as Delete } from '../../../assets/images/newIcon/delete.svg';
import { useEffect, useState } from 'react';
import { isDisabledButton } from './functions';
import Chat from './Chat';
import { useDispatch } from 'react-redux';
import { createProject, getBusiness, getCountry, getLanguage, getSearchLanguages, getTopic } from '../actions';
import SelectMulti from '../../../components/NewUI/MultiSelect';
import { operationSystemOptions } from '../../../utils/data';
import CreateOffer from '../../../components/Modals/CreateOffer';
import { vaultService } from '../../../core/services/vault-service';
import { useNavigate } from 'react-router-dom';

const WizardCreate = () => {
    const [step, setStep] = useState(1);
    const [editOffer, setEditOffer] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({
        countries: '',
        languages: [],
        topics: [],
        description: '',
        name: '',
        business: '',
        goods: [],
    });
    const [accountValues, setAccountValues] = useState({
        operationSystem: '',
        age: '',
    });
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [business, setBusiness] = useState([]);
    const [topics, setTopics] = useState([]);
    const [messages, setMessages] = useState([]);
    const [suggestions, setSuggestions] = useState([
        'Hello!',
        'How can I assist you?',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project.',
        'Tell me more about your project. Tell me more about your project Tell me more about your project Tell me more about your project Tell me more about your project Tell me more about your project',
    ]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const mutateObject = (obj) => {
        return Object.keys(obj).map((item) => ({ label: obj[item], value: item }));
    };

    const loadData = async () => {
        const country = await dispatch(getCountry());
        const businessResponse = await dispatch(getBusiness());
        const topic = await dispatch(getTopic());

        if (topic) {
            const optionsTopics = topic.map((item) => ({ label: item.topic, value: item.id }));
            setTopics(optionsTopics);
        }

        if (businessResponse) {
            const optionsBusiness = Object.values(businessResponse).map((item) => ({ label: item, value: item }));
            setBusiness(optionsBusiness);
        }

        if (country) {
            const optionsCountry = mutateObject(country);
            setCountries(optionsCountry);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const onChangeValue = (key, value) => {
        setValues({ ...values, [key]: value });
    };

    const onChangeAccountValue = (key, value) => {
        setAccountValues({ ...accountValues, [key]: value });
    };

    const optionsAge = [
        { label: 'Clear', value: '' },
        ...Array.from({ length: 36 }).map((_, index) => ({
            label: String(index),
            value: String(index),
        })),
    ];

    const handleChangeCountry = async (value) => {
        onChangeValue('countries', value);
        const language = await dispatch(getLanguage(value));
        if (language) {
            const optionsLanguage = mutateObject(language);
            setLanguages(optionsLanguage);
        }
    };

    const handleOpenModal = () => {
        setIsOpenModal(true);
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
        setEditOffer(null);
    };

    const handleClickEdit = (item) => {
        setEditOffer(item);
        handleOpenModal();
    };

    const handleDeleteOffers = (id) => {
        const filterOffers = values.goods.filter((_, index) => index !== id);
        setValues({ ...values, goods: filterOffers });
    };

    const createProjects = async () => {
        setIsLoading(true);

        const options = { ...values, countries: [values.countries] };
        const resp = await dispatch(createProject(options, userId));

        if (resp) {
            navigate('/panel-buyer/wizzard');
        }
        setIsLoading(false);
    };

    const factoryActionButton = () => {
        if (step <= 5) return () => setStep(step + 1);
        return createProjects;
    };

    const handleSearchLanguages = async (value) => {
        if (value === '') {
            const language = await dispatch(getLanguage(values.countries));
            if (language) {
                const optionsLanguage = mutateObject(language);

                const selectedLanguages = languages.filter(
                    (lang) => values.languages.includes(lang.value),
                );

                const newLanguages = optionsLanguage.filter((item) => !selectedLanguages.some((el) => el.value === item.value));

                setLanguages([...selectedLanguages, ...newLanguages]);
            }
        } else {
            const language = await dispatch(getSearchLanguages(value));

            if (language) {
                const optionsLanguage = mutateObject(language);

                const selectedLanguages = languages.filter(
                    (lang) => values.languages.includes(lang.value),
                );

                const newLanguages = optionsLanguage.filter((item) => !selectedLanguages.some((el) => el.value === item.value));

                setLanguages([...selectedLanguages, ...newLanguages]);
            }
        }
    };

    const factoryShowContentByStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="WizardCreate-content-item-inputs">
                        <Input
                            placeholder="Country"
                            value={values.countries}
                            onChange={handleChangeCountry}
                            options={countries}
                            positionDrop="top"
                        />
                        <SelectMulti
                            searchItems
                            searchAction={handleSearchLanguages}
                            multi
                            placeholder="Languages"
                            value={values.languages}
                            onChange={(value) => onChangeValue('languages', value)}
                            options={languages}
                            positionDrop="top"
                            disabled={!values.countries}
                        />
                    </div>
                );
            case 2:
                return (
                    <div className="WizardCreate-content-item-inputs">
                        <Input
                            placeholder="OC"
                            value={accountValues.operationSystem}
                            onChange={(value) => onChangeAccountValue('operationSystem', value)}
                            options={operationSystemOptions}
                            positionDrop="top"
                            firstItemOpacity
                        />
                        <Input
                            placeholder="Age"
                            value={accountValues.age}
                            onChange={(value) => onChangeAccountValue('age', value)}
                            options={optionsAge}
                            positionDrop="top"
                            firstItemOpacity
                        />
                    </div>
                );
            case 3:
                return (
                    <div className="WizardCreate-content-item-inputs">
                        <Input
                            placeholder="Business model"
                            value={values.business}
                            onChange={(value) => onChangeValue('business', value)}
                            options={business}
                            positionDrop="top"
                        />
                    </div>
                );
            case 4:
                return (
                    <div className="WizardCreate-content-item-inputs">
                        <SelectMulti
                            multi
                            placeholder="Topics"
                            value={values.topics}
                            onChange={(value) => onChangeValue('topics', value)}
                            options={topics}
                            positionDrop="top"
                        />
                        <Input
                            placeholder="Description"
                            value={values.description}
                            onChange={(value) => onChangeValue('description', value)}
                        />
                    </div>
                );
            case 5:
                return (
                    <div className="WizardCreate-content-item-inputs">
                        <div className="WizardCreate-content-offers">
                            {values.goods.length !== 0 ? (
                                <div className="WizardCreate-content-offers-list">
                                    <div className="WizardCreate-content-offers-list-item">
                                        <span>Name</span>
                                        <span>Description</span>
                                        <span>Price</span>
                                        <div></div>
                                    </div>
                                    {values.goods.map((item, index) => (
                                        <div className="WizardCreate-content-offers-list-item" key={index}>
                                            <span>{item.name}</span>
                                            <span>{item.data}</span>
                                            <span>{item.price}</span>
                                            <div>
                                                <Edit
                                                    onClick={() => handleClickEdit({ ...item, id: index })}
                                                    className="TemplateList-icon"
                                                />
                                                <Delete onClick={() => handleDeleteOffers(index)} className="TemplateList-icon" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="WizardCreate-content-offers-no-content">
                                    <span>No offers yet. Add your first offer!</span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className="WizardCreate-content-item-inputs">
                        <Input
                            placeholder="Name project"
                            value={values.name}
                            onChange={(value) => onChangeValue('name', value)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    const handleSendMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    return (
        <div className="WizardCreate">
            <div className="WizardCreate-title">Create Project</div>
            <div className="WizardCreate-wrapper">
                <div className="WizardCreate-content">
                    <div className="WizardCreate-content-title">
                        Settings {step === 5 && <Button onClick={handleOpenModal}>Offers +</Button>}{' '}
                    </div>

                    <div className="WizardCreate-content-item">
                        {factoryShowContentByStep()}

                        <div className="WizardCreate-content-item-buttons">
                            {step > 1 && (
                                <Button buttonType="gray" onClick={() => setStep(step - 1)}>
                                    Back
                                </Button>
                            )}
                            <Button
                                isLoading={isLoading}
                                buttonType="gray"
                                onClick={factoryActionButton()}
                                disabled={isDisabledButton(step, values, accountValues)}
                            >
                                {step <= 5 ? 'Continue' : 'Create'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="WizardCreate-content" style={{ minHeight: '273px' }}>
                    <div className="WizardCreate-content-title">Chat AI</div>
                    <Chat messages={messages} onSendMessage={handleSendMessage} suggestions={suggestions} />
                </div>
            </div>
            {isOpenModal && (
                <CreateOffer
                    closeModal={handleCloseModal}
                    show={isOpenModal}
                    setValues={setValues}
                    values={values}
                    edit={editOffer}
                />
            )}
        </div>
    );
};

export default WizardCreate;
