import './WizardList.scss';
import Button from '../../../components/NewUI/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProjects } from '../actions';
import { useEffect, useState } from 'react';
import { ReactComponent as Edit } from '../../../assets/images/newIcon/edit.svg';
import { ReactComponent as Delete } from '../../../assets/images/newIcon/delete.svg';
import { ReactComponent as Show } from '../../../assets/images/newIcon/show.svg';

const WizardList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    const loadData = async () => {
        const resp = await dispatch(getProjects());
        setData(resp);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="WizardList">
            <div className="WizardList-header">
                <div className="WizardList-header-title">Wizzard projects</div>
                <Button onClick={() => navigate('/project/create')}>Create</Button>
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item  WizardList-item">Name</div>
                    <div className="Table-head-item  WizardList-item">Description</div>
                    <div className="Table-head-item  WizardList-item">Business</div>
                    <div className="Table-head-item  WizardList-item">Countries</div>
                    <div className="Table-head-item  WizardList-item">Languages</div>
                    <div className="Table-head-item  WizardList-item text-right">Actions</div>
                </div>
                <div className="Table-body">
                    {data.length !== 0 ? (
                        data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item  WizardList-item">{item.name}</div>
                                <div className="Table-body-row-item  WizardList-item">{item.description}</div>
                                <div className="Table-body-row-item  WizardList-item">{item.business}</div>
                                <div className="Table-body-row-item  WizardList-item">{item.countries.join(',')}</div>
                                <div className="Table-body-row-item  WizardList-item">{item.languages.join(',')}</div>
                                <div className="Table-body-row-item  WizardList-item">
                                    <div className="WizardList-item-actions">
                                        <Show />
                                        <Edit />
                                        <Delete />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {/*{orders.meta?.total > orders.meta?.per_page && (*/}
                        {/*    <ReactPaginate*/}
                        {/*        previousLabel="<"*/}
                        {/*        nextLabel=">"*/}
                        {/*        pageClassName="page-item"*/}
                        {/*        pageLinkClassName="page-link"*/}
                        {/*        previousClassName="page-item"*/}
                        {/*        previousLinkClassName="page-link"*/}
                        {/*        nextClassName="page-item"*/}
                        {/*        nextLinkClassName="page-link"*/}
                        {/*        breakLabel="..."*/}
                        {/*        breakClassName="page-item"*/}
                        {/*        breakLinkClassName="page-link"*/}
                        {/*        pageCount={orders.meta?.total / orders.meta?.per_page}*/}
                        {/*        marginPagesDisplayed={2}*/}
                        {/*        pageRangeDisplayed={1}*/}
                        {/*        onPageChange={changePage}*/}
                        {/*        containerClassName="pagination"*/}
                        {/*        activeClassName="active"*/}
                        {/*        forcePage={page - 1}*/}
                        {/*    />*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WizardList;
