export const DashboardSvg = () => {
    return (
        <>
            <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="clip3_8295">
                        <rect id="Newspaper" width="24.000000" height="24.000000" fill="white" fillOpacity="0" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clip3_8295)">
                    <path
                        id="Vector"
                        d="M12 19.1426C8.05469 19.1426 4.85693 15.9453 4.85693 12C4.85693 8.05469 8.05469 4.85742 12 4.85742C15.9448 4.85742 19.1426 8.05469 19.1426 12C19.1426 15.9453 15.9448 19.1426 12 19.1426Z"
                        stroke="#111111"
                        strokeOpacity="1.000000"
                        strokeWidth="1.442308"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Intersect"
                        d="M19.1431 12C19.1431 8.05469 15.9448 4.85742 12 4.85742L12 12L18.0581 15.7871C18.7456 14.6895 19.1431 13.3906 19.1431 12Z"
                        stroke="#111111"
                        strokeOpacity="1.000000"
                        strokeWidth="1.500000"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </>
    );
};
