import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Input from '../../NewUI/Input';
import { getTopic } from '../../../pages/SysAdmin/action';
import { orderCreate } from '../../../pages/Bayer/actions';
import { vaultService } from '../../../core/services/vault-service';
import Button from '../../NewUI/Button';

const ModalCreateOrder = ({ closeModal, show }) => {
    const [isLoading, setIsLoading] = useState(false);
    const topics = useSelector((state) => state.serverReducer.topics);
    const [build, setBuild] = useState({
        topic_id: null,
        domain_name: '',
        desc: '',
        count: 1,
    });

    const optionsTopics = topics.map((item) => ({ label: item.topic, value: item.id }));

    const service = vaultService();

    const { id } = service.getItem('user');

    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getTopic());
    };

    useEffect(() => {
        loadData();
    }, []);

    const closedModal = () => {
        closeModal();
        setBuild({
            topic_id: null,
            domain_name: '',
            desc: '',
            count: 1,
        });
    };

    const handleChangeSelect = (value) => {
        setBuild({ ...build, topic_id: value });
    };

    const createOrder = async () => {
        setIsLoading(true);
        const options = {
            ...build,
            user_id: id,
            count: Number(build.count),
        };
        const resp = await dispatch(orderCreate(options));
        if (resp) {
            closedModal();
        }
        setIsLoading(false);
    };

    const handleChangeInput = (value, key) => {
        setBuild({ ...build, [key]: value });
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        if (build.topic_id === null) return true;
        if (build.domain_name?.length === 0) return true;
        if (build.count === 0 || build.count.length === 0 || build.count === '0') return true;
        return false;
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-gd">
            <Modal.Header closeButton>
                <Modal.Title>Заказ домена</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row className="d-flex flex-column gap-2">
                        <Col xs={12}>
                            <Input
                                onChange={handleChangeSelect}
                                placeholder={'Topic'}
                                options={optionsTopics}
                                value={build.topic_id}
                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'domain_name')}
                                type="text"
                                id="domain_name"
                                placeholder={'Желаемый домен'}
                                value={build?.domain_name ? build?.domain_name : ''}
                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'desc')}
                                type="text"
                                id="desc"
                                placeholder={'Комментарий'}
                                value={build?.desc ? build?.desc : ''}
                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'count')}
                                placeholder={'Количество заказов'}
                                value={build?.count ? build?.count : ''}
                            />
                        </Col>
                    </Row>
                    <div className="CreateOrder-buttons">
                        <Button disabled={isDisableButton()} onClick={createOrder} isLoading={isLoading}>
                            Заказать
                        </Button>
                        <Button buttonType="gray" variant="secondary" onClick={closedModal}>
                            Отмена
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreateOrder;
