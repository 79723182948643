export const TrackingSvg = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.0001 16.843C14.675 16.843 16.8435 14.6745 16.8435 11.9996C16.8435 9.3247 14.675 7.15625 12.0001 7.15625C9.32519 7.15625 7.15674 9.3247 7.15674 11.9996C7.15674 14.6745 9.32519 16.843 12.0001 16.843Z"
                    stroke="#111111"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M12 7.15652V5.00391" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 18.9964V16.8438" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.8433 12H18.9959" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.00391 12H7.15652" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
};
