import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../NewUI/Button';

const UICardContainer = ({
    title,
    children,
    titleButton,
    link,
    titleActionButton,
    titleButtonLink,
    linkGit,
    action,
    isLoading,
    backLink,
    titleActionButtonTwo,
    actionTwo,
    titleActionButtonThree,
    actionThree,
    titleActionButtonFour,
    actionFour,
    titleActionButtonFive,
    actionFive,
}) => {
    const navigate = useNavigate();

    const handleClickLink = (link) => {
        navigate(link);
    };

    const clickShowFull = (link) => {
        window.open(`${link}`, '_blank');
    };
    return (
        <>
            <Card className="wrapper p-0">
                {backLink && (
                    <span onClick={() => handleClickLink(backLink)} className="Back">
                        Назад
                    </span>
                )}
                <Card.Header style={{ height: '60px', padding: '1rem' }}>
                    <div className="d-flex align-items-center justify-content-between h-100">
                        <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                            <h5>{title}</h5>
                            <div className="d-flex gap-2 align-items-center">
                                {titleButton && link && (
                                    <Link style={{ textDecoration: 'none' }} to={link}>
                                        <Button variant="primary">{titleButton}</Button>
                                    </Link>
                                )}
                                {titleButtonLink && linkGit && (
                                    <Button variant="primary" onClick={() => clickShowFull(linkGit)}>
                                        {titleButtonLink}
                                    </Button>
                                )}
                                {titleActionButton && action && (
                                    <Button variant="primary" onClick={action} style={{ height: '38px' }}>
                                        {isLoading ? <Spinner animation="border" size="sm" /> : titleActionButton}
                                    </Button>
                                )}
                                {titleActionButtonTwo && actionTwo && (
                                    <Button variant="primary" onClick={actionTwo} style={{ height: '38px' }}>
                                        {isLoading ? <Spinner animation="border" size="sm" /> : titleActionButtonTwo}
                                    </Button>
                                )}
                                {titleActionButtonThree && actionThree && (
                                    <Button variant="primary" onClick={actionThree} style={{ height: '38px' }}>
                                        {isLoading ? <Spinner animation="border" size="sm" /> : titleActionButtonThree}
                                    </Button>
                                )}
                                {titleActionButtonFour && actionFour && (
                                    <Button variant="primary" onClick={actionFour} style={{ height: '38px' }}>
                                        {isLoading ? <Spinner animation="border" size="sm" /> : titleActionButtonFour}
                                    </Button>
                                )}
                                {titleActionButtonFive && actionFive && (
                                    <Button variant="primary" onClick={actionFive} style={{ height: '38px' }}>
                                        {isLoading ? <Spinner animation="border" size="sm" /> : titleActionButtonFive}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>{children}</Card.Body>
            </Card>
        </>
    );
};

export default UICardContainer;
