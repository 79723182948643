import { helperError } from '../../core/helpers/helperError';
import axios from 'axios';
import { API } from '../../core/api';
import { SET_TOPIC } from '../SysAdmin/types';

export const getCountry = () => async () => {
    try {
        const resp = await axios.get(`http://95.216.4.23:8481/api/v1/static/countries`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const getLanguage = (countryCode) => async () => {
    try {
        const resp = await axios.get(`http://95.216.4.23:8481/api/v1/static/language/${countryCode}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const getBusiness = () => async () => {
    try {
        const resp = await axios.get(`http://95.216.4.23:8481/api/v1/static/business`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const getTopic = () => async () => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/topics`);
        return resp.data.data;
    } catch (error) {
        helperError(error);
    }
};

export const createProject = (options, userId) => async () => {
    try {
        const resp = await axios.post(`http://95.216.4.23:8481/api/v1/projects/create/${userId}`, options);
        return resp.data.data;
    } catch (error) {
        helperError(error);
    }
};

export const getProjects = () => async () => {
    try {
        const resp = await axios.get(`http://95.216.4.23:8481/api/v1/projects`);
        return resp.data.data;
    } catch (error) {
        helperError(error);
    }
};

export const getSearchLanguages = (languageCode) => async () => {
    try {
        const resp = await axios.get(`http://95.216.4.23:8481/api/v1/static/language/search/${languageCode}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};
