import React, { useState, useRef } from 'react';
import './DropdownWrapper.scss';
import OutsideClick from '../NewUI/OutsideClick';

const DropdownWrapper = ({
    children,
    childrenDrop,
    alignDrop = 'left',
    positionDrop = 'bottom', // Позиція за замовчуванням
    activeDrop,
    onChange,
    style,
    styleDropdown,
    styleTop,
    childrenBottom,
    isNoWrapperChildrenDrop,
    onClickDrop,
}) => {
    const [active, setActive] = useState(false);
    const [dropPosition, setDropPosition] = useState({ top: 0, left: 0 });
    const dropdownTopRef = useRef(null);
    const dropRef = useRef(null);

    const handleOutsideClick = () => {
        if (activeDrop === undefined) {
            setActive(false);
        } else {
            onChange?.(false);
        }
    };

    const handleTopClick = () => {
        const isActive = activeDrop === undefined ? !active : !activeDrop;
        if (activeDrop === undefined) {
            setActive(isActive);
        } else {
            onChange?.(isActive);
        }

        const topElement = dropdownTopRef.current;
        if (topElement) {
            const rect = topElement.getBoundingClientRect();
            const dropElement = dropRef.current;
            const dropWidth = dropElement ? dropElement.offsetWidth : 0;
            const dropHeight = dropElement ? dropElement.offsetHeight : 0;
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            let left = rect.left;
            let top;

            // Вертикальна позиція
            if (positionDrop === 'top') {
                top = rect.top - dropHeight - 8;
            } else {
                top = rect.bottom + 8;
            }

            // Горизонтальна позиція
            if (alignDrop === 'right') {
                left = rect.right - dropWidth;
            }

            // Перевірка виходу за межі вікна
            if (left + dropWidth > viewportWidth) {
                left = viewportWidth - dropWidth - 16;
            }
            if (left < 16) {
                left = 16;
            }
            if (top + dropHeight > viewportHeight) {
                top = viewportHeight - dropHeight - 16;
            }
            if (top < 16) {
                top = 16;
            }

            setDropPosition({ top, left });
        }
    };

    return (
        <OutsideClick onClickOutside={handleOutsideClick}>
            <div
                className={`dropdown-wrapper ${activeDrop === undefined ? (active ? 'active' : '') : activeDrop ? 'active' : ''}`}
                style={styleDropdown}
            >
                <div className="dropdown-top" ref={dropdownTopRef} style={styleTop} onClick={handleTopClick}>
                    {children}
                </div>

                {(activeDrop || active) && (
                    <div
                        ref={dropRef}
                        className={`dropdown-drop ${alignDrop}`}
                        style={{
                            ...style,
                        }}
                        onClick={onClickDrop}
                    >
                        {isNoWrapperChildrenDrop ? (
                            <>
                                {childrenDrop}
                                {childrenBottom}
                            </>
                        ) : (
                            <div className="dropdown-drop-wrapper">
                                {childrenDrop}
                                {childrenBottom}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </OutsideClick>
    );
};

export default DropdownWrapper;
