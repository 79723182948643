import ReactPaginate from 'react-paginate';
import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Stats } from '../../../assets/images/stats.svg';
import { ReactComponent as Delete } from '../../../assets/images/deleteIcon.svg';
import { vaultService } from '../../../core/services/vault-service';
import { changePagesStats, deleteStatsDomain, getDomainStats } from '../actions';
import { useNavigate } from 'react-router-dom';
import UIFormInput from '../../../components/NewUI/Input';
import SelectMulti from '../../../components/NewUI/MultiSelect';
import ModalAccept from '../../../components/Modals/ModalAccept';
import Button from '../../../components/NewUI/Button';
import ModalStats from '../../../components/Modals/ModalStats';

const ListStatsDomain = () => {
    const [search, setSearch] = useState('');
    const [profileStatuses, setProfileStatuses] = useState([]);
    const domains = useSelector((state) => state.buyerReducer.domainsStats);
    const page = useSelector((state) => state.buyerReducer.pageStats);
    const timeoutRef = useRef(null);
    const [isShowAccept, setIsShowAccept] = useState(false);
    const [currentDomain, setCurrentDomain] = useState(null);
    const [isLoadingDelete, setIsLoadingDelete] = useState(null);
    const [isShowStats, setIsShowStats] = useState(false);

    const closeOpenModalStats = () => {
        setIsShowStats(!isShowStats);
    };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesStats(currentPage));
        await dispatch(getDomainStats(userId, page, search, profileStatuses));
    };

    const loadData = async () => {
        await dispatch(getDomainStats(userId, page, search, profileStatuses));
    };

    useEffect(() => {
        loadData();
    }, [profileStatuses]);

    const handleClickStats = (domain) => {
        navigate(`/statistic/${domain}`);
    };

    const openModal = (domain) => {
        setIsShowAccept(true);
        setCurrentDomain(domain);
    };

    const closeModal = () => {
        setIsShowAccept(false);
        setCurrentDomain(null);
    };

    const handleChangeInput = (value) => {
        setSearch(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomainStats(userId, page, value, profileStatuses));
        }, 300);
    };

    const factoryColorStatusStats = (status) => {
        switch (status) {
            case 'Processing':
                return 'text-warning text-bold';
            case 'Success':
                return 'text-success text-bold';
            case 'Error':
                return 'text-danger text-bold';
            default:
                return '-';
        }
    };

    const factoryColorStatus = (status) => {
        switch (status) {
            case 1:
                return 'text-warning text-bold';
            case 2:
                return 'text-success text-bold';
            case 3:
                return 'text-danger text-bold';
            default:
                return '-';
        }
    };

    const factoryNameStatus = (status) => {
        switch (status) {
            case 1:
                return 'Paused';
            case 2:
                return 'Active';
            case 3:
                return 'Ban';
            default:
                return '-';
        }
    };

    const optionsStatuses = [
        { label: 'Paused', value: 1 },
        { label: 'Active', value: 2 },
        { label: 'Ban', value: 3 },
    ];

    const handleChangeSelect = (status) => {
        setProfileStatuses(status);
    };

    const handleDeleteDomain = async () => {
        setIsLoadingDelete(currentDomain);
        await dispatch(deleteStatsDomain(userId, currentDomain));
        await loadData();
        closeModal();
        setIsLoadingDelete(null);
        setCurrentDomain(null);
    };

    return (
        <div className="ListDomainStats">
            <div className="ListDomainStats-filters">
                <div className="ListDomainStats-filters-select">
                    <SelectMulti
                        onChange={(e) => handleChangeSelect(e)}
                        title={''}
                        options={optionsStatuses}
                        placeholder="Profile status"
                        multi
                        value={profileStatuses}
                        // styleBlock={{margin: "0"}}
                    />
                </div>
                <div className="ListDomainStats-filters-input">
                    <UIFormInput
                        label=""
                        onChange={handleChangeInput}
                        type="text"
                        id="search"
                        placeholder="Search domain"
                        value={search}
                    />
                </div>
                <Button onClick={closeOpenModalStats}>Добавить домен</Button>
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item">Name</div>
                    <div className="Table-head-item pl-2">Status</div>
                    <div className="Table-head-item pl-2">Account Status</div>
                    <div className="Table-head-item">Uuid</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {domains.domains?.length !== 0 ? (
                        domains.domains.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item">{item.domain}</div>
                                <div className="Table-body-row-item">
                                    <div className={`TemplateList-type ${factoryColorStatusStats(item?.profile?.status)}`}>
                                        {item?.profile?.status}
                                    </div>
                                </div>
                                <div className={`Table-body-row-item`}>
                                    <div className={`TemplateList-type  ${factoryColorStatus(item?.profile_status)}`}>
                                        {factoryNameStatus(item?.profile_status)}
                                    </div>
                                </div>
                                <div className="Table-body-row-item">{item.uuid}</div>
                                <div className="Table-body-row-item ml-auto justify-content-end">
                                    <Stats className="icon-stats" onClick={() => handleClickStats(item.domain)} />
                                    <Delete className="icon-stats" onClick={() => openModal(item.domain)} />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {domains.total > 15 && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={domains.total / 15}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>

            {isShowAccept && (
                <ModalAccept
                    isLoading={isLoadingDelete === currentDomain}
                    show={isShowAccept}
                    title={`Вы точно хотите удалить ${currentDomain} ?`}
                    closeModal={closeModal}
                    regenerate={handleDeleteDomain}
                />
            )}
            {isShowStats && <ModalStats show={isShowStats} closeModal={closeOpenModalStats} />}
        </div>
    );
};

export default ListStatsDomain;
