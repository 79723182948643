export const StatisticsSvg = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.13539 16.091C5.29398 17.5312 6.44821 18.6851 7.88769 18.8497C9.22167 19.0023 10.596 19.1431 11.9998 19.1431C13.4036 19.1431 14.7779 19.0023 16.1119 18.8497C17.5514 18.6851 18.7056 17.5312 18.8642 16.091C19.0103 14.7639 19.1426 13.3967 19.1426 12.0003C19.1426 10.6038 19.0103 9.23667 18.8642 7.90952C18.7056 6.46936 17.5514 5.31546 16.1119 5.15083C14.7779 4.99827 13.4036 4.85742 11.9998 4.85742C10.596 4.85742 9.22167 4.99827 7.88769 5.15083C6.44821 5.31546 5.29398 6.46936 5.13539 7.90952C4.98925 9.23667 4.85693 10.6038 4.85693 12.0003C4.85693 13.3967 4.98925 14.7639 5.13539 16.091Z"
                    stroke="#111111"
                    strokeWidth="1.5"
                />
                <path
                    d="M8.05273 11.7897H9.52137L10.8371 9.0166L12.7243 14.9839L14.4787 11.7897H15.9473"
                    stroke="#111111"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
