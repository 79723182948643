export const isLeapYear = () => {
    const currentYear = new Date().getFullYear();

    return (currentYear % 4 === 0 && currentYear % 100 !== 0) || currentYear % 400 === 0;
};

export const getFirstDayOfMonth = (month, year) => {
    const date = new Date(year, month, 1);

    let dayOfWeek = date.getDay();

    if (dayOfWeek === 0) {
        dayOfWeek = 7;
    }
    return dayOfWeek - 1;
};
