export const isDisabledButton = (step, values, accountValues) => {
    switch (step) {
        case 1:
            return !values.countries || !values.languages;
        case 2:
            return !accountValues.operationSystem || !accountValues.age;
        case 3:
            return !values.business;
        case 4:
            return !values.topics || !values.description;
        case 5:
            return values.goods.length === 0;
        case 6:
            return !values.name;
        default:
            return false;
    }
};
