import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { vaultService } from '../../core/services/vault-service';
import { changeTabAggregator, getAccountList } from './actions';
import { Card } from 'react-bootstrap';
import './AggregatorMail.scss';
import AccountList from './AccountList';
import MessageList from './MessageList';
import ModalCreateAccount from '../../components/Modals/ModalCreateAccount';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import Button from '../../components/NewUI/Button';

const AggregatorMail = () => {
    const { tab } = useSelector((state) => state.aggregatorReducer);
    const { pageAccount } = useSelector((state) => state.aggregatorReducer);
    const [isShowAddedAccount, setIsShowAddedAccount] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [pendingEmail, setPendingEmail] = useState(null);
    const navigate = useNavigate();

    const service = vaultService();

    const storageTab = service.getItem('tabAggregator');

    const activeTab = storageTab ? storageTab : tab;

    const dispatch = useDispatch();

    const handleOpenModal = () => {
        setIsShowAddedAccount(!isShowAddedAccount);
    };

    const handleOpenIsShowModal = () => {
        setIsShow(!isShow);
    };

    const handleCloseModal = () => {
        setIsShowAddedAccount(!isShowAddedAccount);
        setPendingEmail(null);
    };

    const handleCloseIsShowModal = () => {
        setIsShow(!isShow);
    };

    const handleChangeTabs = (tab) => {
        dispatch(changeTabAggregator(tab));
        service.setItem('tabAggregator', tab);
    };

    const handleClickButtonPending = (item) => {
        setPendingEmail(item);
        handleOpenModal();
    };

    const loadData = async () => {
        await dispatch(getAccountList(pageAccount));
    };

    const handleClickBack = () => {
        navigate(ROUTES.sysAdminPanel.path);
    };

    return (
        <>
            <Card className="Aggregator">
                <Card.Header className="border-0">
                    <div className="Aggregator-header">
                        <span
                            className={`Aggregator-header-title ${activeTab === 1 && 'active'}`}
                            onClick={() => handleChangeTabs(1)}
                        >
                            Список акаунтов
                        </span>
                        <span
                            className={`Aggregator-header-title ${activeTab === 2 && 'active'}`}
                            onClick={() => handleChangeTabs(2)}
                        >
                            Список сообщений
                        </span>
                        {activeTab === 1 && (
                            <div className="Aggregator-header-buttons">
                                <Button onClick={handleOpenIsShowModal}>Новый акаунт</Button>
                                <Button onClick={handleOpenModal}>Добавить акаунт</Button>
                            </div>
                        )}
                    </div>
                </Card.Header>
                <Card.Body>
                    {activeTab === 1 && <AccountList handleClickButtonPending={handleClickButtonPending} />}
                    {activeTab === 2 && <MessageList />}
                </Card.Body>

                {isShowAddedAccount && (
                    <ModalCreateAccount
                        pendingEmail={pendingEmail}
                        show={isShowAddedAccount}
                        closeModal={handleCloseModal}
                        loadData={loadData}
                    />
                )}
                {isShow && <ModalCreateAccount added show={isShow} closeModal={handleCloseIsShowModal} loadData={loadData} />}
            </Card>
        </>
    );
};

export default AggregatorMail;
