import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import { registerAccount } from './actions';
import { NotificationManager } from 'react-notifications';
import './styles.scss';
import Button from '../../components/NewUI/Button';
import Input from '../../components/NewUI/Input';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [pincode, setPincode] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleChangeEmail = (value) => {
        setEmail(value);
    };

    const handleChangePassword = (value) => {
        setPassword(value);
    };

    const handleChangeName = (value) => {
        setName(value);
    };

    const handleChangePinCode = (value) => {
        setPincode(value);
    };

    const submitClickHandler = async () => {
        if (password !== pincode) {
            NotificationManager.error('Пароли не совпадают', '', 3500);
            return;
        }
        const resp = await dispatch(registerAccount(email, password, name, pincode));
        if (resp) {
            navigate(ROUTES.authorization.link);
        }
    };

    const submitClickCancel = async () => {
        navigate(ROUTES.authorization.link);
    };

    const validationEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length === 0) return null;
        if (!emailRegex.test(email)) return 'Не корректный Email';
        return null;
    };

    const validationName = () => {
        if (name.length === 0) return null;
        if (name.length > 10) return 'Имя пользователя должно быть не больше 10 символов';
        return null;
    };

    const isDisabledButton = () => {
        if (name.length === 0) return true;
        if (password.length === 0) return true;
        if (pincode.length === 0) return true;
        if (email.length === 0) return true;
        if (typeof validationEmail() === 'string') return true;
        if (typeof validationName() === 'string') return true;
        return false;
    };

    return (
        <div className="Login">
            <div className="Login-form">
                <div className="Login-form-title">Sign Up</div>
                <div className="Login-form-border" />

                <div className="d-flex flex-column gap-4 justify-content-center">
                    <div className="position-relative">
                        <Input type="text" placeholder="Name" value={name} autoComplete="off" onChange={handleChangeName} />
                        {validationName() && <span className="error-message-name">{validationName()}</span>}
                    </div>
                    <div className="position-relative">
                        <Input
                            className={`form-control ${typeof validationEmail() === 'string' ? 'error' : ''}`}
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={handleChangeEmail}
                            autoComplete="new-email"
                        />
                        {validationEmail() && <span className="error-message">{validationEmail()}</span>}
                    </div>
                    <Input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        value={password}
                        autoComplete="new-password"
                        onChange={handleChangePassword}
                    />
                    <Input
                        className="form-control"
                        type="password"
                        autoComplete="off"
                        placeholder="Repeat password"
                        value={pincode}
                        onChange={handleChangePinCode}
                    />
                </div>
                <Button className="Login-button" variant="primary" disabled={isDisabledButton()} onClick={submitClickHandler}>
                    Sign up
                </Button>
                <div className="Login-form-border" />

                <div className="Login-form-footer">
                    Already have an account?
                    <a onClick={submitClickCancel}> Sign In</a>
                </div>
            </div>
        </div>
    );
};

export default Register;
