import React, { useState, useEffect, useRef } from 'react';
import { vaultService } from '../../core/services/vault-service';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './Header.scss';
import { ArrowDropSvg } from '../Icons/NavigationMenu/ArrowDropdown';
import docCookies from '../../core/services/cookie';
import { ROUTES } from '../../core/helpers/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logout } from '../../assets/images/logout.svg';
import { ReactComponent as ArrowLeftSvg } from '../../assets/images/NewUI/back.svg';
import Button from '../NewUI/Button';

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null); // Реф для дропдауна
    const service = vaultService();
    const user = service.getItem('user');
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeDropdown();
        }
    };

    const toggleTooltip = async () => {
        await docCookies.removeItem('token');
        await service.removeItems('role');
        await closeDropdown();
        navigate(ROUTES.authorization.path);
        await window.location.reload();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const pathnames = [
        '/project/create',
        '/statistic/',
        'filter/',
        '/panel-webmaster/list-domain',
        '/filters-templates/create',
        '/filters-templates/edit',
        '/filters-templates',
        '/domains/',
    ];

    const isBack = pathnames.find((item) => pathname.includes(item));

    return (
        <div className="header">
            {isBack ? (
                <Button onClick={() => navigate(-1)} buttonType="back" arrowLeft={<ArrowLeftSvg />}>
                    Back
                </Button>
            ) : (
                <div></div>
            )}
            <NotificationContainer />
            <div ref={dropdownRef} className={`user ${isDropdownOpen ? 'active' : ''}`} onClick={toggleDropdown}>
                <span className="user-name">{user?.name?.charAt(0)}</span>
                <span>{user?.email}</span>
                <ArrowDropSvg className={isDropdownOpen ? 'rotated' : 'no-rotated'} />
                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <p onClick={toggleTooltip}>
                            <Logout />
                            Logout
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
