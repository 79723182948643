import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import '../../../assets/styles/table.scss';
import Button from '../../../components/NewUI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changePagesReserved, getDomeinsBuyerReserved, getListWhitePage, getRkCheck } from '../actions';
import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { vaultService } from '../../../core/services/vault-service';
import { changeStatusDomainWarm, setWhitePageDomain } from '../../Farmer/actions';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoNorm } from '../../../assets/images/infoNorm.svg';
import { ReactComponent as Edit } from '../../../assets/images/edit_icon.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import ModalCreateOrder from '../../../components/Modals/ModalCreateOrder';
import ModalSettingRK from '../../../components/Modals/SettingsRK';
import ModalAccept from '../../../components/Modals/ModalAccept';
import Input from '../../../components/NewUI/Input';
import { ROUTES } from '../../../core/helpers/routes';
import moment from 'moment';
import ModalChangeStatus from '../../../components/Modals/ModalChangeStatus';
import ModalUploadWhitePage from '../../../components/Modals/ModalUploadWhitePage';

const MyDomain = ({ showStatusDomain, closeOpenModalOrder, isShowOrder }) => {
    const [isShowUploadWhitePage, setIsShowUploadWhitePage] = useState(false);
    const [domainUploadedWhitePage, setDomainUploadedWhitePage] = useState(null);
    const [isShowRk, setIsShowRk] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [isLoadingServer, setIsLoadingServer] = useState(null);
    const [activeWhitePage, setActiveWhitePage] = useState([]);
    const [editRk, setEditRk] = useState(false);
    const [isShowAccept, setIsShowAccept] = useState(false);
    const [name, setName] = useState('');
    const [isShowChangeStatus, setIsShowChangeStatus] = useState(false);
    const [changeStatusDomain, setChangeStatusDomain] = useState(null);
    const timeoutRef = useRef(null);

    let timer = null;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const service = vaultService();

    const userId = service.getItem('user').id;

    const { domainsReserved, pageReserved } = useSelector((state) => state.buyerReducer);

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesReserved(currentPage));
        await dispatch(getDomeinsBuyerReserved(currentPage, name));
    };

    const loadData = async () => {
        await dispatch(getDomeinsBuyerReserved(pageReserved, name));
    };

    const closeModalRk = () => {
        setIsShowRk(false);
        setSelectedDomain(null);
        setEditRk(false);
    };

    const openAcceptModal = (item) => {
        setIsShowAccept(true);
        setSelectedDomain(item);
    };

    const closeAcceptModal = () => {
        setIsShowAccept(false);
        setSelectedDomain(null);
    };

    const openModalRk = async (item) => {
        const resp = await dispatch(getRkCheck(item.id));
        if (resp) {
            if (resp.status === 204) {
                setSelectedDomain(item);
                setIsShowRk(true);
                return;
            }
            setSelectedDomain(item);
            setIsShowRk(true);
            setEditRk(true);
            return;
        }
    };

    useEffect(() => {
        loadData();
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleChangeStatusDomain = async (item, status) => {
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: status,
            domain_data: item.domain_data,
            user_id: item.user_id,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
    };

    const openModalChangeStatus = (domain) => {
        setChangeStatusDomain(domain);
        setIsShowChangeStatus(!isShowChangeStatus);
    };

    const handleCloseModalChangeStatus = () => {
        setIsShowChangeStatus(!isShowChangeStatus);
        setChangeStatusDomain(null);
    };

    const handleClickButtonPut = async (item) => {
        if (isLoading !== null) return;
        setIsLoading(item.id);
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: 'free',
            domain_data: item.domain_data,
            user_id: null,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
        setIsLoading(null);
        closeAcceptModal();
    };

    const showWhitePage = (element) => {
        if (!element.domain_data?.white_page?.white_id) return null;
        const finded = activeWhitePage.find((item) => item.id === element.domain_data?.white_page?.white_id);

        if (finded) {
            return finded;
        }
        return null;
    };

    const handleClickButtonSetServer = async (item) => {
        if (isLoadingServer !== null) return;
        setIsLoadingServer(item.id);
        const domain = item.id;
        await dispatch(setWhitePageDomain(domain));
        loadData();
        setIsLoadingServer(null);
    };

    const showStatusWhitePage = (domain) => {
        return (
            <div className="info_norm_buyer">
                <Tooltip
                    id={`my-tooltip-status-white-page${domain.id}`}
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0">Статус заливки: {domain?.upload_status ?? '-'}</p>
                        <p className="m-0 p-0">
                            Последняя заливка:{' '}
                            {domain?.uploaded_at ? moment(domain?.uploaded_at).format('DD.MM.YYYY HH:mm:ss') : '-'}
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showLink = (domain) => {
        if (
            domain?.domain_data?.thank_you_page?.ty ||
            domain?.domain_data?.success_page?.success ||
            domain?.domain_go_push_history
        ) {
            return (
                <div className="info_norm_buyer">
                    <InfoNorm data-tooltip-id={`my-tooltip-${domain.id}`} />
                    <Tooltip
                        id={`my-tooltip-${domain.id}`}
                        place="bottom"
                        openOnClick
                        clickable
                        style={{
                            zIndex: '9999999999',
                            margin: 0,
                            padding: '10px',
                            paddingBottom: 0,
                        }}
                    >
                        <div className="info_norm_buyer-content pb-2">
                            {domain?.domain_data?.success_page?.success && (
                                <p>Витрина: {`https://${domain.name}/${domain?.domain_data?.success_page?.success}`}</p>
                            )}
                            {domain?.domain_data?.thank_you_page?.ty && (
                                <p>Пуш: {`https://${domain.name}/${domain?.domain_data?.thank_you_page?.ty}`}</p>
                            )}
                            {domain?.domain_go_push_history && (
                                <div className="d-flex flex-column gap-1">
                                    <p className="m-0">Push: {domain?.domain_go_push_history?.push}</p>
                                    <p className="m-0">Sub: {domain?.domain_go_push_history?.sub}</p>
                                    <p className="m-0">Sw: {domain?.domain_go_push_history?.sw}</p>
                                    <p className="m-0">v4: {domain?.domain_go_push_history?.v4}</p>
                                    <p className="m-0">Tracker url: {domain?.domain_go_push_history?.tracker_url}</p>
                                </div>
                            )}
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return false;
    };

    const runUpdateCheckbox = () => {
        const INTERVAL = 15000;
        timer = setTimeout(async () => {
            const data = await dispatch(getListWhitePage());

            if (data) {
                setActiveWhitePage(data);
                if (data.length !== 0) {
                    runUpdateCheckbox();
                    return;
                }
                clearTimeout(timer);
            }
        }, INTERVAL);
    };

    const handleChangeInput = (value) => {
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeinsBuyerReserved(pageReserved, value));
        }, 300);
    };

    const handleClickFiltering = (id) => {
        navigate(`/filter/${id}`);
    };

    const handleClickDomain = (name) => {
        navigate(ROUTES.statistic.link.replace(':name', name));
    };

    const handleOpenUploadWhitePage = (domain) => {
        setIsShowUploadWhitePage(true);
        setDomainUploadedWhitePage(domain);
    };

    const handleCloseUploadWhitePage = () => {
        setIsShowUploadWhitePage(false);
        setDomainUploadedWhitePage(null);
    };

    return (
        <div className="MyDomain">
            <div className="MyDomain-filters">
                <div className="MyDomain-filters-input">
                    <Input
                        label=""
                        onChange={handleChangeInput}
                        type="text"
                        id="search"
                        className="MyDomain-filters-input"
                        placeholder="Search domain"
                        value={name}
                    />
                </div>
            </div>

            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item MyDomain-item">Name</div>
                    <div className="Table-head-item">Status</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {domainsReserved.data?.length !== 0 ? (
                        domainsReserved.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item MyDomain-item">
                                    {showStatusDomain(item)}
                                    {item.name}
                                </div>
                                <div className="Table-body-row-item  MyDomain-item-icon">
                                    {item.status} <Edit onClick={() => openModalChangeStatus(item)} />
                                </div>
                                <div className="Table-body-row-item ml-auto justify-content-end  MyDomain-item-action">
                                    {showLink(item)}
                                    <Button onClick={() => openModalRk(item)}>Настроить РК</Button>
                                    <Button onClick={() => handleClickFiltering(item.id)}>Фильтрация</Button>
                                    <Button onClick={() => handleOpenUploadWhitePage(item)}>White Page</Button>
                                    <div data-tooltip-id={`my-tooltip-status-white-page${item.id}`}>
                                        <Button
                                            buttonType="gray"
                                            isLoading={isLoadingServer === item.id}
                                            onClick={() => handleClickButtonSetServer(item)}
                                        >
                                            Залить
                                        </Button>
                                        {showStatusWhitePage(item)}
                                    </div>
                                    <Button buttonType="gray" onClick={() => openAcceptModal(item)}>
                                        Открепить
                                    </Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {domainsReserved.meta?.total > domainsReserved.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={domainsReserved.meta?.total / domainsReserved.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={pageReserved - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isShowRk && (
                <ModalSettingRK
                    show={isShowRk}
                    closeModal={closeModalRk}
                    domain={selectedDomain}
                    userId={userId}
                    edit={editRk}
                    loadDomain={loadData}
                />
            )}

            {isShowOrder && <ModalCreateOrder show={isShowOrder} closeModal={closeOpenModalOrder} />}

            {isShowChangeStatus && (
                <ModalChangeStatus
                    domain={changeStatusDomain}
                    show={isShowChangeStatus}
                    closeModal={handleCloseModalChangeStatus}
                    changeStatus={handleChangeStatusDomain}
                />
            )}

            {isShowAccept && (
                <ModalAccept
                    isLoading={isLoading === selectedDomain.id}
                    show={isShowAccept}
                    title={'Вы точно хотите этого ?'}
                    closeModal={closeAcceptModal}
                    regenerate={() => handleClickButtonPut(selectedDomain)}
                />
            )}

            {isShowUploadWhitePage && (
                <ModalUploadWhitePage
                    domain={domainUploadedWhitePage}
                    show={isShowUploadWhitePage}
                    closeModal={handleCloseUploadWhitePage}
                    loadData={() => {}}
                />
            )}
        </div>
    );
};

export default MyDomain;
