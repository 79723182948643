import './AccountList.scss';
import Button from '../../../components/NewUI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { activeAccount, changePagesAccount, disableAccount, getAccountList } from '../actions';
import ModalAccept from '../../../components/Modals/ModalAccept';

const AccountList = ({ handleClickButtonPending }) => {
    const { accountList, pageAccount } = useSelector((state) => state.aggregatorReducer);
    const [isLoading, setIsLoading] = useState(null);
    const [isShowAcceptModal, setIsShowAcceptModal] = useState(false);
    const [disabledAccount, setDisabledAccount] = useState(null);

    const dispatch = useDispatch();

    const loadData = async () => {
        await dispatch(getAccountList(pageAccount));
    };

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesAccount(currentPage));
        await dispatch(getAccountList(currentPage));
    };

    const handleOpenAcceptModal = (item) => {
        setDisabledAccount(item);
        setIsShowAcceptModal(!isShowAcceptModal);
    };

    const handleCloseAcceptModal = () => {
        setDisabledAccount(null);
        setIsShowAcceptModal(!isShowAcceptModal);
    };

    const handleClickButton = async () => {
        setIsLoading(true);
        await dispatch(disableAccount(disabledAccount?.id));
        await loadData();
        handleCloseAcceptModal();
        setDisabledAccount(null);
        setIsLoading(false);
    };

    const handleClickButtonActive = async (item) => {
        setIsLoading(item.id);
        await dispatch(activeAccount(item?.id));
        await loadData();
        setIsLoading(null);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="AccountList">
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item">Email</div>
                    <div className="Table-head-item">Status</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {accountList.data?.length !== 0 ? (
                        accountList.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item">{item.email}</div>
                                <div className="Table-body-row-item">{item.status}</div>
                                <div className="Table-body-row-item ml-auto justify-content-end">
                                    {item.status.includes('disabled') && (
                                        <Button
                                            className="button-account-list"
                                            onClick={() => handleClickButtonActive(item)}
                                            isLoading={isLoading === item.id}
                                        >
                                            Активировать
                                        </Button>
                                    )}
                                    {item.status.includes('pending') && (
                                        <Button
                                            className="button-account-list"
                                            onClick={() => handleClickButtonPending(item)}
                                            isLoading={isLoading === item.id}
                                        >
                                            Подтвердить
                                        </Button>
                                    )}
                                    {!item.status.includes('disabled') && !item.status.includes('pending') && (
                                        <Button className="button-account-list" onClick={() => handleOpenAcceptModal(item)}>
                                            Отключить
                                        </Button>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {accountList.meta?.total > accountList.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={accountList.meta?.total / accountList.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={pageAccount - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isShowAcceptModal && (
                <ModalAccept
                    regenerate={handleClickButton}
                    title="Вы точно хотите отключить этот акаунт ?"
                    closeModal={handleCloseAcceptModal}
                    show={isShowAcceptModal}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
};

export default AccountList;
