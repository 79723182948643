import './styles.scss';
import Button from '../../../components/NewUI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changePages, getDomeinsBuyer } from '../actions';
import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { changeStatusDomainWarm } from '../../Farmer/actions';
import { vaultService } from '../../../core/services/vault-service';
import Input from '../../../components/NewUI/Input';

const ListDomain = ({ showStatusDomain }) => {
    const { domains, page } = useSelector((state) => state.buyerReducer);
    const [isLoading, setIsLoading] = useState(null);
    const [name, setName] = useState('');
    const timeoutRef = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        dispatch(getDomeinsBuyer(page, name));
    };

    const service = vaultService();
    const { id } = service.getItem('user');

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePages(currentPage));
        await dispatch(getDomeinsBuyer(currentPage, name));
    };

    const handleClickButton = async (item) => {
        if (isLoading !== null) return;
        setIsLoading(item.id);
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: 'reserved',
            domain_data: item.domain_data ?? [],
            user_id: id,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
        setIsLoading(null);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleChangeInput = (value) => {
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeinsBuyer(page, value));
        }, 300);
    };

    return (
        <div className="ListDomainNotReserved">
            <div className="ListDomainNotReserved-filters">
                <div className="ListDomainNotReserved-filters-input">
                    <Input
                        label=""
                        onChange={handleChangeInput}
                        type="text"
                        id="search"
                        className="ListDomainNotReserved-filters-input"
                        placeholder="Search domain"
                        value={name}
                    />
                </div>
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item ListDomainNotReserved-item">Name</div>
                    <div className="Table-head-item ListDomainNotReserved-item">Topic</div>
                    <div className="Table-head-item">GEO</div>
                    <div className="Table-head-item">Status (прогрева РК)</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {domains.data?.length !== 0 ? (
                        domains.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item  ListDomainNotReserved-item">
                                    {showStatusDomain(item)}
                                    {item.name}
                                </div>
                                <div className="Table-body-row-item  ListDomainNotReserved-item">{item['topic'].topic}</div>
                                <div className="Table-body-row-item">{item.server.geolocation}</div>
                                <div className="Table-body-row-item">{item.status}</div>
                                <div className=" ml-auto justify-content-end">
                                    <Button onClick={() => handleClickButton(item)} isLoading={item.id === isLoading}>
                                        Зарезервивовать
                                    </Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {domains.meta?.total > domains.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={domains.meta?.total / domains.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListDomain;
