import React, { useCallback, useState } from 'react';
import './CalendarBlock.scss';
import Button from '../NewUI/Button';
import { getFirstDayOfMonth, isLeapYear } from '../../helpers/functions';
import ArrowLeftSvg from '../Icons/ArrowLeftSvg';
import ArrowRightSvg from '../Icons/ArrowRightSvg';

const periods = [
    { name: 'Today', type: 'today' },
    { name: 'Yesterday', type: 'yesterday' },
    { name: 'This week', type: 'this-week' },
    { name: 'Last week', type: 'last-week' },
    { name: 'This month', type: 'this-month' },
    { name: 'Last month', type: 'last-month' },
    { name: 'Custom', type: 'custom' },
];

const dataWeeks = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const monthsData = {
    January: 31,
    February: isLeapYear() ? 29 : 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
};

const getPeriodValue = (period) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    switch (period) {
        case 'today':
            return { startDate: currentDate, endDate: currentDate };
        case 'yesterday': {
            const date = new Date(currentDate);
            date.setDate(date.getDate() - 1);
            return { startDate: date, endDate: date };
        }
        case 'this-week': {
            const startDate = new Date(currentDate);
            startDate.setDate(startDate.getDate() - ((startDate.getDay() + 6) % 7));
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + 6);
            return { startDate, endDate };
        }
        case 'this-month': {
            const startDate = new Date(currentDate);
            startDate.setDate(1);
            const endDate = new Date(startDate);
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
            return { startDate, endDate };
        }
        default:
            return undefined;
    }
};

const CalendarBlock = ({ ranged = false, value, onChange, clearBg, onClose, minimum, maximum, resettable }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedValue, setSelectedValue] = useState(value || undefined);
    const [selectionPending, setSelectionPending] = useState(false);

    const changeMonth = (offset) => {
        const date = new Date(currentDate);
        date.setMonth(date.getMonth() + offset);
        setCurrentDate(date);
    };

    const onDateClick = useCallback(
        (date) => {
            if (selectionPending && selectedValue) {
                setSelectedValue({
                    startDate: date < selectedValue.startDate ? date : selectedValue.startDate,
                    endDate: date > selectedValue.startDate ? date : selectedValue.startDate,
                });
                setSelectionPending(false);
            } else {
                setSelectedValue({ startDate: date, endDate: date });
                if (ranged) setSelectionPending(true);
            }
        },
        [ranged, selectedValue, selectionPending],
    );

    return (
        <div className={`calendar-block ${clearBg ? 'no-bg' : ''}`}>
            {/*<div className="calendar-control">*/}
            {/*    {periods.map((item) => {*/}
            {/*        const periodValue = getPeriodValue(item.type);*/}
            {/*        if (!ranged && periodValue?.startDate !== periodValue?.endDate) return null;*/}

            {/*        return (*/}
            {/*            <div*/}
            {/*                key={item.type}*/}
            {/*                className={`calendar-control-item ${selectedValue?.type === item.type ? 'active' : ''}`}*/}
            {/*                onClick={() => {*/}
            {/*                    if (periodValue) {*/}
            {/*                        setSelectedValue(periodValue);*/}
            {/*                        setCurrentDate(periodValue.startDate);*/}
            {/*                    }*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                {item.name}*/}
            {/*            </div>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</div>*/}
            <div className="calendar-content">
                <div className="calendar-months">
                    {[currentDate.getMonth() - 1, currentDate.getMonth()].map((month, index) => {
                        const year = currentDate.getFullYear();
                        const firstDayMonth = getFirstDayOfMonth(month, year);
                        const monthNames = Object.keys(monthsData);
                        const monthName = monthNames[(month + 12) % 12];
                        const daysArray = Array.from({ length: monthsData[monthName] }, (_, i) => i + 1);

                        return (
                            <div key={monthName} className="calendar-month-block">
                                <div className="month-header">
                                    {index === 0 ? (
                                        <div className="arrow-calendar" onClick={() => changeMonth(-1)}>
                                            <ArrowLeftSvg />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <span>{monthName}</span>
                                    {index === 1 ? (
                                        <div className="arrow-calendar" onClick={() => changeMonth(1)}>
                                            <ArrowRightSvg />
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div className="weekdays">
                                    {dataWeeks.map((day, i) => (
                                        <div key={i} className="weekday">
                                            {day}
                                        </div>
                                    ))}
                                </div>
                                <div className="month-days">
                                    {Array.from({ length: firstDayMonth }).map((_, i) => (
                                        <div key={`empty-${i}`} className="empty-day"></div>
                                    ))}
                                    {daysArray.map((day) => {
                                        const date = new Date(year, month, day);
                                        const isDisabled = date < (minimum || -Infinity) || date > (maximum || Infinity);
                                        const isSelected =
                                            selectedValue &&
                                            new Date(date).getTime() === new Date(selectedValue.startDate).getTime();

                                        const isInRange =
                                            selectedValue && date >= selectedValue.startDate && date <= selectedValue.endDate;

                                        return (
                                            <div
                                                key={day}
                                                className={`day ${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${
                                                    isInRange && !isSelected ? 'in-range' : ''
                                                }`}
                                                onClick={() => !isDisabled && onDateClick(date)}
                                            >
                                                <span>{day}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="calendar-actions">
                    {resettable && (
                        <>
                            <Button onClick={() => onChange?.(undefined)}>Reset</Button>
                            <div className="spacer" />
                        </>
                    )}
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => onChange?.(ranged ? selectedValue : selectedValue?.startDate)}>Apply</Button>
                </div>
            </div>
        </div>
    );
};

export default CalendarBlock;
