import Button from '../../../components/NewUI/Button';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { changeStatusDomainWarm, setWhitePageDomain } from '../../Farmer/actions';
import { ROUTES } from '../../../core/helpers/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as InfoNorm } from '../../../assets/images/infoNorm.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { getDomeinsBuyerReserved, getUser } from '../actions';
import { changePagesReserved, getRkCheck, uploadWhitePage } from '../../Bayer/actions';
import './styles.scss';
import { getDomainCheck } from '../../SysAdmin/action';
import ModalSettingRK from '../../../components/Modals/SettingsRK';
import UIFormInput from '../../../components/NewUI/Input';
import moment from 'moment/moment';

const ListDomainBuyer = () => {
    const [isShowRk, setIsShowRk] = useState(false);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [isLoadingServer, setIsLoadingServer] = useState(null);
    const [user, setUser] = useState('');
    const [checks, setChecks] = useState([]);
    const [editRk, setEditRk] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const fileInputRef = useRef(null);
    const [name, setName] = useState('');
    const [isLoadingUpload, setIsLoadingUpload] = useState(null);
    const [currentDomainUpload, setCurrentDomainUpload] = useState(null);
    const timeoutRef = useRef(null);

    const handleFileChange = async (event) => {
        if (!currentDomainUpload) return;
        setIsLoadingUpload(currentDomainUpload);
        const file = event.target.files[0];
        await dispatch(uploadWhitePage(file, currentDomainUpload));
        setIsLoadingUpload(null);
        setCurrentDomainUpload(null);
    };

    const handleClickUploadWhitePage = async (id) => {
        await setCurrentDomainUpload(id);
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    let timer = null;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { userId } = useParams();

    const { domainsReserved, pageReserved } = useSelector((state) => state.buyerReducer);

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        dispatch(changePagesReserved(currentPage));
        await dispatch(getDomeinsBuyerReserved(currentPage, userId, name));
    };

    const loadData = async () => {
        await dispatch(getDomeinsBuyerReserved(pageReserved, userId, name));
    };

    const closeModalRk = () => {
        setIsShowRk(false);
        setSelectedDomain(null);
        setEditRk(false);
    };

    const openModalRk = async (item) => {
        const resp = await dispatch(getRkCheck(item.id));
        if (resp) {
            if (resp.status === 204) {
                setSelectedDomain(item);
                setIsShowRk(true);
                return;
            }
            setSelectedDomain(item);
            setIsShowRk(true);
            setEditRk(true);
            return;
        }
    };

    useEffect(() => {
        loadData();
        loadBuyer();
        loadStatusDomain();
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const handleClickButtonSetServer = async (item) => {
        if (isLoadingServer !== null) return;
        setIsLoadingServer(item.id);
        const domain = item.id;
        const whitePage = item.domain_data?.white_page?.white_id;
        await dispatch(setWhitePageDomain(domain, whitePage));
        loadData();
        setIsLoadingServer(null);
    };

    const showLink = (domain) => {
        if (
            domain?.domain_data?.thank_you_page?.ty ||
            domain?.domain_data?.success_page?.success ||
            domain?.domain_go_push_history
        ) {
            return (
                <div className="info_norm_buyer">
                    <InfoNorm data-tooltip-id={`my-tooltip-${domain.id}`} />
                    <Tooltip
                        id={`my-tooltip-${domain.id}`}
                        place="bottom"
                        openOnClick
                        clickable
                        style={{
                            zIndex: '9999999999',
                            margin: 0,
                            padding: '10px',
                            paddingBottom: 0,
                        }}
                    >
                        <div className="info_norm_buyer-content">
                            {domain?.domain_data?.success_page?.success && (
                                <p>Витрина: {`https://${domain.name}/${domain?.domain_data?.success_page?.success}`}</p>
                            )}
                            {domain?.domain_data?.thank_you_page?.ty && (
                                <p>Пуш: {`https://${domain.name}/${domain?.domain_data?.thank_you_page?.ty}`}</p>
                            )}
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return false;
    };

    const loadBuyer = async () => {
        const user = await dispatch(getUser(userId));
        if (user) {
            setUser(user?.data?.name);
        }
    };

    const loadStatusDomain = async () => {
        const check = await dispatch(getDomainCheck());
        if (check) {
            setChecks(check);
        }
    };

    const showStatusDomain = (domain) => {
        const check = checks.find((item) => item.name === domain.name);

        switch (check?.status) {
            case 'up':
                return <div className="status-up-buyer" />;
            case 'down':
                return <div className="status-down-buyer" />;
            case 'unknown':
                return <div className="status-unknown-buyer" />;
            default:
                return <div className="status-none-buyer" />;
        }
    };

    const handleClickButtonPut = async (item) => {
        if (isLoading !== null) return;
        setIsLoading(item.id);
        const options = {
            topic_id: item['topic'].id,
            name: item.name,
            registrar: item.registrar,
            domain_ip: item.domain_ip,
            domain_ip_alt: item.domain_ip_alt,
            server_id: item.server.id,
            status: 'free',
            domain_data: item.domain_data,
            user_id: null,
            type: item.type,
        };
        const resp = await dispatch(changeStatusDomainWarm(options, item.id));
        if (resp) {
            loadData();
        }
        setIsLoading(null);
    };

    const handleChangeInput = (value) => {
        setName(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            await dispatch(getDomeinsBuyerReserved(pageReserved, userId, value));
        }, 300);
    };

    const showStatusWhitePage = (domain) => {
        return (
            <div className="info_norm_buyer">
                <Tooltip
                    id={`my-tooltip-status-white-page${domain.id}`}
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0">Статус заливки: {domain?.upload_status ?? '-'}</p>
                        <p className="m-0 p-0">
                            Последняя заливка:{' '}
                            {domain?.uploaded_at ? moment(domain?.uploaded_at).format('DD.MM.YYYY HH:mm:ss') : '-'}
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <div className="ListDomainBuyer">
            <div className="ListDomainBuyer-header">
                <div className="ListDomainBuyer-header-title">{`Список доменов ${user}`}</div>
            </div>
            <div className="ListDomainBuyer-filters">
                <UIFormInput
                    onChange={handleChangeInput}
                    className="ListDomainBuyer-filters-input"
                    placeholder="Search domain"
                    value={name}
                />
            </div>
            <div className="Table">
                <div className="Table-head">
                    <div className="Table-head-item ListDomainBuyer-item">Name</div>
                    <div className="Table-head-item">Status</div>
                    <div className="Table-head-item ml-auto  justify-content-end">Actions</div>
                </div>
                <div className="Table-body">
                    {domainsReserved.data?.length !== 0 ? (
                        domainsReserved.data.map((item) => (
                            <div className="Table-body-row" key={item.id}>
                                <div className="Table-body-row-item ListDomainBuyer-item">
                                    {showStatusDomain(item)}
                                    {item.name}
                                </div>
                                <div className="Table-body-row-item  ListDomainBuyer-item-icon">{item.status}</div>
                                <div className="Table-body-row-item ml-auto justify-content-end  ListDomainBuyer-item-action">
                                    {showLink(item)}
                                    <Button onClick={() => openModalRk(item)}>Настроить РК</Button>
                                    <Button
                                        onClick={() => handleClickUploadWhitePage(item.id)}
                                        isLoading={isLoadingUpload === item.id}
                                    >
                                        White Page
                                    </Button>
                                    <div data-tooltip-id={`my-tooltip-status-white-page${item.id}`}>
                                        <Button
                                            buttonType="gray"
                                            isLoading={isLoadingServer === item.id}
                                            onClick={() => handleClickButtonSetServer(item)}
                                        >
                                            Залить
                                        </Button>
                                        {showStatusWhitePage(item)}
                                    </div>
                                    <Button
                                        buttonType="gray"
                                        isLoading={isLoading === item.id}
                                        onClick={() => handleClickButtonPut(item)}
                                    >
                                        Открепить
                                    </Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Table-no-content">No data available yet</div>
                    )}
                </div>
                <div className="Table-footer">
                    <div className="Table-pagination">
                        {domainsReserved.meta?.total > domainsReserved.meta?.per_page && (
                            <ReactPaginate
                                previousLabel="<"
                                nextLabel=">"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={domainsReserved.meta?.total / domainsReserved.meta?.per_page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={1}
                                onPageChange={changePage}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={pageReserved - 1}
                            />
                        )}
                    </div>
                </div>
            </div>

            {isShowRk && (
                <ModalSettingRK
                    show={isShowRk}
                    closeModal={closeModalRk}
                    domain={selectedDomain}
                    userId={userId}
                    edit={editRk}
                    loadDomain={loadData}
                />
            )}
        </div>
    );
};

export default ListDomainBuyer;
