import React, { useEffect, useRef } from 'react';
import './Chat.scss';

const Chat = ({ messages, onSendMessage, suggestions }) => {
    const chatEndRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom whenever messages change
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSuggestionClick = (suggestion) => {
        onSendMessage({ text: suggestion, sender: 'user' });
        // Simulate AI response or handle accordingly
        // For demonstration, we'll just echo the message
        setTimeout(() => {
            onSendMessage({ text: `You said: ${suggestion}`, sender: 'ai' });
        }, 500);
    };

    return (
        <div className="Chat">
            <div className="Chat-messages">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`Chat-message ${msg.sender === 'user' ? 'Chat-message--user' : 'Chat-message--ai'}`}
                    >
                        <div className="Chat-message-content">{msg.text}</div>
                    </div>
                ))}
                <div ref={chatEndRef} />
            </div>
            <div className="Chat-suggestions">
                {suggestions.map((suggestion, index) => (
                    <button key={index} className="Chat-suggestion-button" onClick={() => handleSuggestionClick(suggestion)}>
                        {suggestion}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Chat;
