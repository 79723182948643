const DateSvg = ({ ...props }) => {
    return (
        <div {...props}>
            <svg width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    id="Line 3"
                    d="M5.43262 0.857422L5.43262 3.75977"
                    stroke="#888888"
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                    strokeLinecap="round"
                />
                <path
                    id="Line 4"
                    d="M10.5596 0.857422L10.5596 3.75977"
                    stroke="#888888"
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                    strokeLinecap="round"
                />
                <path
                    id="Intersect"
                    d="M4.06641 15.041C2.61963 14.9648 1.45557 13.8223 1.28857 12.3838C1.14697 11.1641 1.02197 9.90918 1.02197 8.62793C1.02197 7.34766 1.14697 6.09277 1.28857 4.87305C1.45557 3.43359 2.61963 2.29199 4.06641 2.21484C5.32324 2.14844 6.60107 2.11328 8 2.11328C9.39941 2.11328 10.6768 2.14844 11.9341 2.21484C13.3804 2.29199 14.5444 3.43359 14.7114 4.87305C14.8535 6.09277 14.978 7.34766 14.978 8.62793C14.978 9.90918 14.8535 11.1641 14.7114 12.3838C14.5444 13.8223 13.3804 14.9648 11.9341 15.041C10.6768 15.1084 9.39941 15.1436 8 15.1436C6.60107 15.1436 5.32324 15.1084 4.06641 15.041Z"
                    stroke="#888888"
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                />
                <path
                    id="Vector 4"
                    d="M1.35547 7.11426L14.7124 7.11426"
                    stroke="#888888"
                    strokeOpacity="1.000000"
                    strokeWidth="1.500000"
                />
            </svg>
        </div>
    );
};

export default DateSvg;
