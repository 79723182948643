import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useState } from 'react';
import SelectSearch from '../../NewUI/Input';

const ModalChangeStatus = ({ closeModal, show, domain, changeStatus }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(domain?.status);

    const options = [
        { label: 'In progress', value: 'in_progress' },
        { label: 'Reserved', value: 'reserved' },
        { label: 'Stop', value: 'stop' },
        { label: 'Unban', value: 'unban' },
        { label: 'Ban', value: 'ban' },
        { label: 'Spend', value: 'spend' },
    ];

    const closedModal = () => {
        closeModal();
    };

    const handleChangeSelect = (e) => {
        setSelectedStatus(e);
    };

    const handleChangeStatusDomain = async () => {
        setIsLoading(true);
        await changeStatus(domain, selectedStatus);
        setIsLoading(false);
        closedModal();
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-binom">
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center gap-2">Изменения статуса - {domain.name}</Modal.Title>
            </Modal.Header>
            <div className="d-flex flex-column gap-3 p-4">
                <Row>
                    <Col className="select-status">
                        <SelectSearch
                            onChange={handleChangeSelect}
                            options={options}
                            placeholder={'Status'}
                            itemLabel={'label'}
                            itemValue={'value'}
                            default={selectedStatus}
                        />
                    </Col>
                </Row>
                <div className="CreateDomain-buttons">
                    <Button
                        style={{ background: 'silver' }}
                        className="wrapper-button-added-path-button"
                        variant="secondary"
                        onClick={closedModal}
                    >
                        Отмена
                    </Button>
                    <Button className="wrapper-button-added-path-button" variant="primary" onClick={handleChangeStatusDomain}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : 'Изменить'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalChangeStatus;
