import React, { useMemo, useState } from 'react';
import './Calendar.scss';
import DateSvg from '../Icons/DateSvg';
import DropdownWrapper from '../DropdownWrapper';
import CalendarBlock from '../CalendarBlock';

const Calendar = ({
    ranged = false,
    value,
    onChange,
    onClose,
    alignDrop = 'left',
    positionDrop = 'bottom',
    placeholder,
    minimum,
    maximum,
    resettable,
    styleTop,
    periodsCustom,
    getPeriodValueCustom,
}) => {
    const [active, setActive] = useState(false);

    const currentValue = useMemo(() => {
        if (!value) {
            return placeholder;
        }

        const { startDate, endDate } = 'startDate' in value ? value : { startDate: value, endDate: undefined };

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


        const startMonthShortName = monthNames[new Date(startDate).getMonth()];
        const currentStartDate = `${new Date(startDate).getDate()} ${startMonthShortName}`;

        if (!endDate) {
            return currentStartDate;
        }

        const endMonthShortName = monthNames[new Date(endDate).getMonth()];
        const currentEndDate = `${new Date(endDate).getDate()} ${endMonthShortName}`;

        return `${currentStartDate} - ${currentEndDate}`;
    }, [value, placeholder]);

    return (
        <DropdownWrapper
            isNoWrapperChildrenDrop
            activeDrop={active}
            onChange={setActive}
            alignDrop={alignDrop}
            positionDrop={positionDrop}
            style={{ padding: 0 }}
            styleTop={styleTop}
            styleDropdown={{ width: '100%' }}
            childrenDrop={
                <CalendarBlock
                    ranged={ranged}
                    minimum={minimum}
                    maximum={maximum}
                    value={value}
                    resettable={resettable}
                    periodsCustom={periodsCustom}
                    getPeriodValueCustom={getPeriodValueCustom}
                    onChange={(value) => {
                        setActive(false);
                        onChange?.(value);
                    }}
                    onClose={() => {
                        setActive(false);
                        onClose?.();
                    }}
                    clearBg
                />
            }
        >
            <div className="calendar">
                <div className="calendar-top">
                    <span className={`calendar-top-value ${value ? 'active' : ''}`}>{currentValue}</span>
                    <div className="calendar-icon">
                        <DateSvg />
                    </div>
                </div>
            </div>
        </DropdownWrapper>
    );
};

export default Calendar;
