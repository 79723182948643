import { DashboardSvg } from '../Icons/NavigationMenu/DashboardSvg';
import { StatisticsSvg } from '../Icons/NavigationMenu/StatisticsSvg';
import { TrackingSvg } from '../Icons/NavigationMenu/TrackingSvg';
import { FinanceSvg } from '../Icons/NavigationMenu/FinanceSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import logo from '../../assets/images/logo.png';
import logoMin from '../../assets/images/MoarTools.svg';
import './Menu.scss';
import { CollapseSvg } from '../Icons/NavigationMenu/CollapseSvg';
import { vaultService } from '../../core/services/vault-service';

const buyerMenuItems = {
    dashboard: {
        icon: <DashboardSvg />,
        text: 'Domains',
        href: '/panel-buyer/domains',
        check: ['/panel-buyer/domains'],
    },
    statistics: {
        icon: <StatisticsSvg />,
        text: 'Statistics',
        href: '/panel-buyer/statistics',
        check: ['/panel-buyer/statistics'],
    },
    tracking: {
        icon: <TrackingSvg />,
        text: 'Wizzard',
        href: '/panel-buyer/wizzard',
        check: ['/panel-buyer/wizzard'],
    },
    finance: {
        icon: <FinanceSvg />,
        text: 'Generators',
        textGray: '$ 14000.27',
        href: '/panel-buyer/generators',
        check: ['/panel-buyer/generators'],
    },
};

const sysadminMenuItems = {
    dashboard: {
        icon: <DashboardSvg />,
        text: 'Servers',
        href: '/sysadmin-panel/servers',
        check: ['/sysadmin-panel/servers'],
    },
    tracking: {
        icon: <TrackingSvg />,
        text: 'Aggregator',
        href: '/sysadmin-panel/aggregator',
        check: ['/sysadmin-panel/aggregator'],
    },
};

const webMasterMenuItems = {
    dashboard: {
        icon: <DashboardSvg />,
        text: 'Buyers',
        href: '/panel-webmaster/list-buyers',
        check: ['/panel-webmaster/list-buyers'],
    },
    statistics: {
        icon: <TrackingSvg />,
        text: 'Orders',
        href: '/panel-webmaster/list-orders',
        check: ['/panel-webmaster/list-orders'],
    },
    finance: {
        icon: <FinanceSvg />,
        text: 'Templates',
        href: '/panel-webmaster/templates',
        check: ['/panel-webmaster/templates'],
    },
};

const menuItemsFactory = (role) => {
    switch (role) {
        case 'buyer':
            return buyerMenuItems;
        case 'webmaster':
            return webMasterMenuItems;
        case 'sysadmin':
            return sysadminMenuItems;
        default:
            return [];
    }
};

const NavigationMenu = ({ isHiddenNavigationMenu, setIsHiddenNavigationMenu }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const service = vaultService();
    const role = service.getItem('role');

    const [currentMenuItems, setCurrentMenuItems] = useState(menuItemsFactory(role));

    const activeHelpCenter = true;

    const isActive = (item) => {
        return Boolean(item.check.find((findItem) => findItem === pathname));
    };
    return (
        <div className={`navigation-menu ${isHiddenNavigationMenu ? 'hidden' : ''}`}>
            <div className="top">
                {isHiddenNavigationMenu ? (
                    <img className="logo-image-collapse" src={logoMin} alt="" />
                ) : (
                    <img className="logo-image" src={logo} alt="" />
                )}
            </div>

            <div className="content">
                <div className="menu">
                    {Object.values(currentMenuItems).map(
                        (item, index, arr) =>
                            !item.disabled && (
                                <div key={item.text + index}>
                                    {/*{arr.length - 1 === index && <Line size="middle" className="line" />}*/}
                                    <a
                                        // href={item.href || ''}
                                        onClick={() => navigate(item.href)}
                                        className={`menu-item ${isActive(item) ? 'active' : ''}`}
                                    >
                                        <div className="menu-item-icon">
                                            {activeHelpCenter && item.iconActive ? item.iconActive : item.icon}
                                        </div>

                                        {!isHiddenNavigationMenu && (
                                            <>
                                                {item.textGray ? (
                                                    <div className="menu-item-wrapper">
                                                        <span className="menu-item-text">{item.text}</span>
                                                        <span
                                                            className="menu-item-text-gray"
                                                            style={isActive(item) ? { color: '#d1d4d9' } : {}}
                                                        ></span>
                                                    </div>
                                                ) : (
                                                    <span className="menu-item-text">{item.text}</span>
                                                )}
                                            </>
                                        )}
                                    </a>
                                </div>
                            ),
                    )}
                </div>

                <div className="menu-item collapse-button" onClick={() => setIsHiddenNavigationMenu(!isHiddenNavigationMenu)}>
                    <div className="menu-item-icon-collapse">
                        <CollapseSvg />
                    </div>

                    {!isHiddenNavigationMenu && <span className="menu-item-text">Collapse</span>}
                </div>
            </div>
        </div>
    );
};

export default NavigationMenu;
