export const CollapseSvg = () => {
    return (
        <>
            <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="clip3_8374">
                        <rect id="Newspaper" width="24.000000" height="24.000000" fill="white" fillOpacity="0" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clip3_8374)">
                    <path
                        id="Vector"
                        d="M19.1426 12C19.1426 15.9453 15.9448 19.1426 12 19.1426C8.05469 19.1426 4.85693 15.9453 4.85693 12C4.85693 8.05469 8.05469 4.85742 12 4.85742C15.9448 4.85742 19.1426 8.05469 19.1426 12Z"
                        stroke="#676C74"
                        strokeOpacity="1.000000"
                        strokeWidth="1.500000"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector"
                        d="M15.2168 12L8.78271 12"
                        stroke="#676C74"
                        strokeOpacity="1.000000"
                        strokeWidth="1.500000"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                    />
                    <path
                        id="Vector"
                        d="M11.0928 9.30859C10.0596 10.127 9.57861 10.6836 8.94971 11.5137C8.72705 11.8066 8.72705 12.1934 8.94971 12.4863C9.57861 13.3164 10.0596 13.873 11.0928 14.6914"
                        stroke="#676C74"
                        strokeOpacity="1.000000"
                        strokeWidth="1.500000"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                    />
                </g>
            </svg>
        </>
    );
};
