import { Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import { addedAccount, getFreeAccount, getMessageAccount, verifyAccount } from '../../../pages/AggregatorMail/actions';
import { useDispatch } from 'react-redux';
import { ReactComponent as InfoNorm } from '../../../assets/images/infoNorm.svg';
import Input from '../../NewUI/Input';
import Button from '../../NewUI/Button';

const ModalCreateAccount = ({ closeModal, show, pendingEmail, loadData, added = false }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [step, setStep] = useState(1);
    const [freeAccount, setFreeAccount] = useState(null);
    const [messages, setMessages] = useState([]);
    const [build, setBuild] = useState({
        email: '',
        password: '',
        host: '',
        username: '',
        port: '',
    });

    const dispatch = useDispatch();

    const closedModal = () => {
        closeModal();
    };

    const handleClickButton = async () => {
        setIsLoading(true);
        const resp = await dispatch(verifyAccount(freeAccount?.id));
        if (resp) {
            closedModal();
            loadData();
        }
        setIsLoading(false);
    };

    const handleClickUpdate = async () => {
        setIsLoadingUpdate(true);
        const readMessages = await dispatch(getMessageAccount(freeAccount.id));
        if (readMessages) {
            setMessages(readMessages.data);
        }
        setIsLoadingUpdate(false);
    };

    const showTitleButton = () => {
        switch (step) {
            case 1:
                return 'Запросить свободный Email';
            case 2:
                return 'Подтвердить правильность пересылки';
            default:
                return '';
        }
    };

    const handleClickGetFreeEmailAccount = async () => {
        setIsLoading(true);
        const option = {
            type: 'imap',
        };
        const account = await dispatch(getFreeAccount(option));

        if (account) {
            setFreeAccount(account);
            setStep(2);
        }

        setIsLoading(false);
    };

    const factoryActionButton = () => {
        switch (step) {
            case 1:
                return handleClickGetFreeEmailAccount;
            case 2:
                return handleClickButton;
            default:
                return '';
        }
    };

    useEffect(() => {
        if (pendingEmail) {
            setFreeAccount(pendingEmail);
            setStep(2);
        }
    }, []);

    const handleClickInfo = () => {
        const url = '/info-setting-forwarding';
        window.open(url, '_blank');
    };

    const handleChangeInput = (value, key) => {
        setBuild({ ...build, [key]: value });
    };

    const handleClickAddedAccount = async () => {
        setIsLoading(true);
        const options = {
            email: build.email,
            type: 'imap',
            credentials: {
                host: build.host,
                password: build.password,
                port: build.port,
                username: build.username,
            },
        };

        const resp = await dispatch(addedAccount(options));

        if (resp) {
            await loadData();
            closedModal();
        }

        setIsLoading(false);
    };

    const isDisabledButton = () => {
        if (build.email.length === 0) return true;
        if (build.username.length === 0) return true;
        if (build.port.length === 0) return true;
        if (build.password.length === 0) return true;
        if (build.host.length === 0) return true;
        return isLoading;
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-create-account">
            <Modal.Header closeButton={step === 1 ? true : false}>
                <Modal.Title className="d-flex align-items-center gap-2 justify-content-between w-100">
                    {added ? 'Новый акаунт' : 'Добавить акаунт'}
                    {step === 2 && !added && (
                        <Button
                            style={{ minWidth: '94px', height: '30px' }}
                            onClick={handleClickUpdate}
                            isLoading={isLoadingUpdate}
                        >
                            Обновить
                        </Button>
                    )}
                </Modal.Title>
            </Modal.Header>
            {!added ? (
                <div className="d-flex flex-column gap-3 p-4">
                    <Row className="CreateAccount">
                        {step === 1 && (
                            <div className="CreateAccount-preview-description">Для начала нужно "Запросить свободный Email"</div>
                        )}
                        {step === 2 && (
                            <div className="CreateAccount-info">
                                <span>
                                    Настройте пересылку на этот акаунт <InfoNorm onClick={handleClickInfo} />
                                </span>
                                <span>{freeAccount?.email}</span>
                                <span>Сообщения:</span>
                                {messages.map((item) => (
                                    <p dangerouslySetInnerHTML={{ __html: item.body }} />
                                ))}
                            </div>
                        )}
                    </Row>
                    <div className="CreateAccount-buttons">
                        <Button buttonType="gray" onClick={closedModal}>
                            Отмена
                        </Button>
                        <Button isLoading={isLoading} onClick={factoryActionButton()}>
                            {showTitleButton()}
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column gap-1 p-4">
                    <Input
                        onChange={(value) => handleChangeInput(value, 'email')}
                        type="text"
                        id="email"
                        placeholder="Email"
                        value={build?.email ? build?.email : ''}
                    />
                    <Input
                        onChange={(value) => handleChangeInput(value, 'username')}
                        type="text"
                        id="username"
                        placeholder="Username"
                        value={build?.username ? build?.username : ''}
                    />
                    <Input
                        onChange={(value) => handleChangeInput(value, 'password')}
                        type="text"
                        id="password"
                        placeholder="Password"
                        value={build?.password ? build?.password : ''}
                    />
                    <Input
                        onChange={(value) => handleChangeInput(value, 'host')}
                        type="text"
                        id="host"
                        placeholder="Host"
                        value={build?.host ? build?.host : ''}
                    />
                    <Input
                        onChange={(value) => handleChangeInput(value, 'port')}
                        type="text"
                        id="port"
                        placeholder="Port"
                        value={build?.port ? build?.port : ''}
                    />
                    <div className="d-flex align-items-center gap-2 ml-auto mt-2">
                        <Button buttonType="gray" onClick={closedModal}>
                            Отмена
                        </Button>
                        <Button disabled={isDisabledButton()} onClick={handleClickAddedAccount} isLoading={isLoading}>
                            Добавить
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ModalCreateAccount;
