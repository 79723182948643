import { Card, Spinner } from 'react-bootstrap';
import './styles.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Save } from '../../assets/images/save.svg';
import { ReactComponent as Add } from '../../assets/images/add.svg';
import {
    createPage,
    deletePage,
    editBlocks,
    editFilterRules,
    editPage,
    getBlocks,
    getFilters,
    getLandings,
    getOutCloak,
    getStaticLanding,
    putOutCloak,
    saveLandings,
} from './actions';
import RulesBinom from '../../components/Binom/RulesBinom';
import Services from '../../components/Binom/Services';
import Landings from '../../components/Binom/Landings';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as Question } from '../../assets/images/question.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { setWhitePageDomain } from '../Farmer/actions';
import { ROUTES } from '../../core/helpers/routes';
import PageFilter from './PageFilter';
import ModalCreatePage from '../../components/Modals/ModalCreatePage';
import ModalTemplates from '../../components/Modals/ModalTemplates';

const Filters = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSaving, setIsLoadingSaving] = useState(null);
    const [pages, setPages] = useState(null);
    const [ourCloak, setOurCloak] = useState(null);
    const [blocks, setBlocks] = useState(null);
    const [landings, setLandings] = useState(null);
    const [optionsLandings, setOptionsLandings] = useState([]);
    const [isShowModalPage, setIsShowModalPage] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [isShowTemplate, setIsShowTemplate] = useState(false);

    const openModalPage = (page) => {
        if (page?.name) {
            setCurrentPage(page);
        }
        setIsShowModalPage(true);
    };

    const openModalTemplate = () => {
        setIsShowTemplate(true);
    };

    const closeModalTemplate = () => {
        setIsShowTemplate(false);
    };

    const onCloseModalPage = () => {
        setCurrentPage(null);
        setIsShowModalPage(false);
    };

    const loadDataStatic = async () => {
        const resp = await dispatch(getStaticLanding(params?.id));
        const resultArray = Object.entries(resp).map(([key, value]) => ({
            label: key,
            value: value,
        }));

        setOptionsLandings(resultArray);
    };

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loadPages = async () => {
        const resp = await dispatch(getFilters(params?.id));
        if (resp) {
            const filterPages = resp.data;
            setPages(filterPages);
        }
    };

    const loadData = async () => {
        setIsLoading(true);
        const resp = await dispatch(getFilters(params?.id));
        const landing = await dispatch(getLandings(params?.id));
        const cloak = await dispatch(getOutCloak(params?.id));
        const block = await dispatch(getBlocks(params?.id));
        if (block) {
            setBlocks(block.data);
        }

        if (cloak) {
            setOurCloak([cloak.data]);
        }

        if (resp) {
            const filterPages = resp.data;
            setPages(filterPages);
        }

        if (landing) {
            setLandings(landing.data);
        }
        await loadDataStatic();

        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    const hasInvalidData = (rules) => {
        const checkResultPage = (resultPage) => {
            return resultPage.some((element) => element.data.length === 0 || element.data === 'http://');
        };

        const processRules = (rules) => {
            for (let item of rules) {
                if (item.result_page && checkResultPage(item.result_page)) {
                    return true;
                }

                if (item.rules && processRules(item.rules)) {
                    return true;
                }
            }
            return false;
        };

        return processRules(rules);
    };

    const processRules = (rules) => {
        return rules.map((item, index) => {
            const rule = {
                active: item.active,
                name: item.name,
                default: item.default,
                result: item.result,
                position: index + 1,
                result_split: item.result_split,
                target: item.target,
                filters: item.filters?.map((el, indexFilter) => {
                    const filter = {
                        active: el.active,
                        get_data_method_id: el.filter?.id,
                        operator_method_id: el.operator.id,
                        value: el.value,
                        position: indexFilter + 1,
                    };
                    if (el.id && typeof el.id === 'number') {
                        filter.id = el.id;
                    }
                    return filter;
                }),
            };

            if (item.result_page) {
                rule.result_page = item.result_page.map((element, indexLand) => ({
                    ...element,
                    position: indexLand + 1,
                }));
            }

            if (item.rules) {
                rule.rules = processRules(item.rules);
            }

            if (item.id) {
                rule.id = item.id;
            }

            return rule;
        });
    };

    const handleClickButtonSetServer = async () => {
        const domain = params?.id;
        await dispatch(setWhitePageDomain(domain));
    };

    const saveRules = async (type) => {
        setIsLoadingSaving(type);
        if (pages && pages.length !== 0) {
            const updatePagesRules = pages.map(async (page) => {
                const rules = page.rules;
                if (rules.length === 0) return;
                const findedErrors = rules.find((item) => !item.filters || item.filters.length === 0);
                const hasError = hasInvalidData(rules);
                if (findedErrors) {
                    NotificationManager.error(`В Rules должно быть как минимум одна Filter и один Landing`);
                    setIsLoadingSaving(null);
                    return;
                }

                if (hasError) {
                    NotificationManager.error(`Название Landing не может быть http:// или пустое значение `);
                    setIsLoadingSaving(null);
                    return;
                }
                const options = {
                    rules: processRules(rules),
                };

                const resp = await dispatch(editFilterRules(options, params?.id, page?.page?.id));
                if (resp && type === 'upload') {
                    navigate(ROUTES.panelBayer.link);
                    await handleClickButtonSetServer();
                }
            });

            await Promise.all(updatePagesRules);
        }

        if (blocks) {
            const findedErrors = blocks.find((item) => !item.filters || item.filters.length === 0);
            if (findedErrors) {
                NotificationManager.error(`В Block должно быть как минимум один Filter`);
                setIsLoadingSaving(null);
                return;
            }

            const options = {
                rules: processRules(blocks),
            };

            await dispatch(editBlocks(options, params?.id));
        }

        if (ourCloak) {
            const [cloak] = processRules(ourCloak);
            if (cloak) {
                const optionsCloak = {
                    filters: cloak?.filters,
                    id: cloak?.id,
                    active: cloak?.active,
                };
                await dispatch(putOutCloak(params?.id, optionsCloak));
            }
        }

        if (landings) {
            const optionsLanding = {
                ...landings,
                default_result_page: landings.default_result_page.map((item, index) => ({
                    ...item,
                    position: index + 1,
                })),
            };
            await dispatch(saveLandings(optionsLanding, params?.id));
        }

        setIsLoadingSaving(null);
    };

    const showInfoDownload = () => {
        return (
            <div className="info_norm_buyer">
                <Question className="question-icon" data-tooltip-id="question-tooltip-download" />
                <Tooltip
                    id="question-tooltip-download"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Save - сохраняет текущие изменения фильтров домена в системе, они попадут на сервер после загрузки.
                        </p>
                        <p className="m-0 p-0 w-100">
                            Save&Upload - сохраняет текущие изменения фильтров домена в системе, после чего активирует загрузку
                            сохраненной версии на сервер. Примечание: при активации загрузки выполняется полная выгрузка всех
                            файлов на сервер, а не только фильтрации.
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const handleSetRules = (indexPage, newSubRules) => {
        setPages((prevPages) =>
            prevPages.map((page, idxPage) => {
                if (idxPage === indexPage) {
                    return { ...page, rules: newSubRules };
                }
                return page;
            }),
        );
    };

    const handleDeletePage = async (id) => {
        const filterPages = pages.filter((item) => item.page?.id !== id);
        setPages(filterPages);
        await dispatch(deletePage(params?.id, id));
    };

    const createNewPage = async (build) => {
        const resp = await dispatch(createPage(params?.id, build));
        if (resp.data) {
            return resp.data;
        }
        return null;
    };

    return (
        <Card className="wrapper  p-0">
            <Card.Header
                className="pt-0 pb-0 d-flex align-items-center justify-content-between"
                style={{ height: '60px', minHeight: '60px', paddingLeft: '30px', paddingRight: '30px' }}
            >
                <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                    <div className="d-flex align-items-center gap-2">
                        {/*<button onClick={() => navigate(-1)} className="wrapper-button-added-path-button">*/}
                        {/*    <span>Back</span>*/}
                        {/*</button>*/}
                        <h5 className="m-0">Filtering</h5>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                        {showInfoDownload()}
                        <button
                            onClick={() => saveRules('save')}
                            style={{ width: '82px' }}
                            className="wrapper-button-added-path-button"
                        >
                            {isLoadingSaving === 'save' ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <>
                                    {' '}
                                    <span>Save</span>
                                    <Save />
                                </>
                            )}
                        </button>
                        <button
                            onClick={() => saveRules('upload')}
                            style={{ width: '132px' }}
                            className="wrapper-button-added-path-button"
                        >
                            {isLoadingSaving === 'upload' ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <>
                                    {' '}
                                    <span>Save&Upload</span>
                                    <Save />
                                </>
                            )}
                        </button>
                        <button
                            onClick={() => navigate(ROUTES.filtersTemplates.link)}
                            style={{ width: '132px' }}
                            className="wrapper-button-added-path-button"
                        >
                            {isLoadingSaving === 'upload' ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <>
                                    {' '}
                                    <span>Templates list</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="pl-0 pr-0">
                {!isLoading && (
                    <div className="Forms">
                        <div className="Right-form">
                            <Services />
                            {ourCloak !== null && (
                                <RulesBinom
                                    rules={ourCloak}
                                    setRules={setOurCloak}
                                    ourСloak={true}
                                    optionsLandings={optionsLandings}
                                    template={openModalTemplate}
                                />
                            )}
                            {pages !== null && (
                                <div className="Page">
                                    <div className="Page-header">Pages</div>
                                    {pages.map((page, indexPage) => (
                                        <PageFilter
                                            key={page.page.id}
                                            page={page}
                                            setRules={(newSubRules) => handleSetRules(indexPage, newSubRules)}
                                            optionsLandings={optionsLandings}
                                            handleDeletePage={() => handleDeletePage(page?.page.id)}
                                            handleOpenModal={() => openModalPage(page.page)}
                                        />
                                    ))}
                                    <button onClick={openModalPage} className="Page-button">
                                        <span>Page</span>
                                        <Add />
                                    </button>
                                </div>
                            )}
                            {landings !== null && (
                                <Landings landings={landings} setLandings={setLandings} optionsLandings={optionsLandings} />
                            )}
                            {blocks !== null && (
                                <RulesBinom rules={blocks} setRules={setBlocks} blocks={true} optionsLandings={optionsLandings} />
                            )}
                        </div>
                    </div>
                )}
                {isShowModalPage && (
                    <ModalCreatePage
                        show={isShowModalPage}
                        closeModal={onCloseModalPage}
                        domainId={params?.id}
                        page={currentPage}
                        loadData={loadPages}
                    />
                )}
                {isShowTemplate && (
                    <ModalTemplates
                        show={isShowTemplate}
                        createNewPage={createNewPage}
                        closeModal={closeModalTemplate}
                        pages={pages}
                        blocks={blocks}
                        ourCloak={ourCloak}
                        setPages={setPages}
                        setBlocks={setBlocks}
                        setOurCloak={setOurCloak}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default Filters;
