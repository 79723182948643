import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import './Select.scss';
import { ReactComponent as ArrowSvg } from '../../../assets/images/NewUI/arrow.svg';
import OutsideClick from '../OutsideClick';

const MultiSelect = ({
    value = '',
    onChange,
    placeholder,
    icon,
    options,
    styleBlock,
    onClickIcon,
    error,
    mergeValueOption,
    disabled,
    positionDrop,
    onBlur,
    placeholderInput,
    isLogin = false,
    firstItemOpacity = false,
    maxLength,
    multi = false,
    searchAction = null,
    searchItems = false,
    ...props
}) => {
    const [isInput, setIsInput] = useState(false);
    const [isDrop, setIsDrop] = useState(false);
    const [valueSearch, setValueSearch] = useState({
        value: '',
        isActive: false,
    });
    const [search, setSearch] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const refInput = useRef(null);

    // Стан для збереження вибраних значень
    const [selectedValues, setSelectedValues] = useState(multi ? [] : value);

    // Синхронізація `selectedValues` з `value`
    useEffect(() => {
        if (multi) {
            setSelectedValues(value || []);
        } else {
            setSelectedValues(value || '');
        }
    }, [value, multi]);

    const filterValueOption = useMemo(() => {
        if (!valueSearch.isActive) return options || [];
        return (options || []).filter((item) => item.label.toLowerCase().includes(valueSearch.value.toLowerCase()));
    }, [options, valueSearch]);

    const middlewareOnChange = (e, selectionStart) => {
        const newValue = e.target.value;

        if (maxLength && newValue.length > maxLength) {
            setErrorMessage(`Maximum number of characters: ${maxLength}`);
            return;
        } else {
            setErrorMessage(null);
        }

        setValueSearch({
            value: newValue,
            isActive: true,
        });

        if (!isDrop) {
            onChange?.(newValue, selectionStart);
        }
    };

    const handleSelectOption = (item) => {
        if (multi) {
            const isSelected = selectedValues.includes(item.value);
            const newValues = isSelected ? selectedValues.filter((val) => val !== item.value) : [...selectedValues, item.value];
            setSelectedValues(newValues);
            onChange?.(newValues);
        } else {
            setSelectedValues(item.value);
            onChange?.(item.value);
            setIsDrop(false);
        }
    };

    const getValueToDisplay = () => {
        if (multi) {
            return selectedValues.map((value) => options.find((opt) => opt.value === value)?.label).join(', ');
        }
        const selectedOption = (options || []).find((opt) => opt.value === selectedValues);
        return selectedOption ? selectedOption.label : selectedValues;
    };

    const handleChangeInputSearch = (e) => {
        const { value } = e.target;
        setSearch(value);
        if (searchAction) {
            searchAction(value);
        }
    };

    return (
        <div className="input-wrapper" style={styleBlock}>
            <div
                className={classNames('input-block', {
                    'error': !!errorMessage || error === 'error',
                    'disabled': disabled,
                    'is-login': isLogin,
                })}
                onClick={() => {
                    if (!disabled) {
                        setIsInput(true);
                        if (refInput.current) {
                            refInput.current.focus();
                        }
                        if (options) {
                            setIsDrop(true);
                        }
                    }
                }}
            >
                <OutsideClick
                    onClickOutside={() => {
                        setValueSearch({
                            value: '',
                            isActive: false,
                        });
                        setIsDrop(false);
                    }}
                >
                    <div className="input-block__content">
                        <div
                            className={classNames('input-block__placeholder', {
                                'is-input': isInput,
                            })}
                        >
                            {!multi || selectedValues.length === 0 ? placeholder : ''}
                        </div>

                        {!multi && (
                            <input
                                {...props}
                                className={classNames('input-block__input', {
                                    'is-input': isInput,
                                })}
                                placeholder={placeholderInput}
                                ref={refInput}
                                value={isDrop && valueSearch.isActive ? valueSearch.value : getValueToDisplay()}
                                onInput={(e) => middlewareOnChange(e, e.target.selectionStart)}
                                onBlur={(e) => {
                                    setIsInput(!!selectedValues);
                                    if (!options) {
                                        onBlur?.(e.target.value, e);
                                        middlewareOnChange(e);
                                    }
                                }}
                                readOnly={!!options}
                            />
                        )}

                        {multi && selectedValues.length > 0 && (
                            <div className="input-block__selected-values" title={getValueToDisplay()}>
                                {getValueToDisplay()}
                            </div>
                        )}
                    </div>

                    {options && (
                        <div
                            className={classNames('input-block__icon', {
                                active: isDrop,
                            })}
                            onClick={(e) => {
                                onClickIcon && onClickIcon(e);
                            }}
                        >
                            <ArrowSvg className={isDrop ? 'active' : ''} />
                        </div>
                    )}

                    {options && isDrop && (
                        <div
                            className={classNames('input-drop', {
                                bottom: positionDrop === 'bottom',
                                top: positionDrop !== 'bottom',
                            })}
                        >
                            {searchAction && searchItems && (
                                <input
                                    {...props}
                                    className={classNames('input-block__input', {
                                        'is-input': isInput,
                                    })}
                                    style={{ marginBottom: '10px', padding: '0 8px' }}
                                    placeholder="Search"
                                    value={search}
                                    onChange={handleChangeInputSearch}
                                />
                            )}
                            {filterValueOption.length > 0 ? (
                                filterValueOption.map((item) => (
                                    <div
                                        className={classNames('input-drop__item', {
                                            'is-active': selectedValues === item.value || selectedValues.includes?.(item.value),
                                            'first-item-opacity': firstItemOpacity,
                                        })}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSelectOption(item);
                                        }}
                                        key={item.value}
                                    >
                                        {mergeValueOption && (
                                            <b style={{ opacity: 0.5, width: 30, display: 'inline-flex' }}>{item.value}</b>
                                        )}
                                        <span>{item.label}</span>
                                    </div>
                                ))
                            ) : (
                                <div>No data available</div>
                            )}
                        </div>
                    )}
                </OutsideClick>
            </div>

            {errorMessage && <div className="input-info-text">{errorMessage}</div>}
        </div>
    );
};

export default React.memo(MultiSelect);
