import { NotificationManager } from 'react-notifications';
import { API } from '../../core/api';
import {
    CHANGE_PAGE,
    CHANGE_PAGE_RESERVED,
    CHANGE_PAGE_STATS,
    CHANGE_TAB_BUYER,
    SET_DOMAIN_BUYER,
    SET_DOMAIN_BUYER_RESERVED,
    SET_DOMAIN_STATS,
} from './types';
import { vaultService } from '../../core/services/vault-service';
import axios from 'axios';
import moment from 'moment';
import { helperError } from '../../core/helpers/helperError';

const service = vaultService();
const user = service.getItem('user');

export const getListBinom = () => async () => {
    const link = `/microservices/filtering/api/v0/user/binom/list`;
    try {
        const resp = await API.get(link);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const uploadWhitePageUuid = (domain, uuid) => async () => {
    const option = {
        uuid,
    };
    try {
        const resp = await API.post(`/microservices/servers-managment/api/v1/domains/${domain}/upload-uuid`, option);
        NotificationManager.success(`White Page Успешно добавлен`, '', 3500);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const getWhitePageByUser = (id) => async () => {
    try {
        const resp = await API.get(`/microservices/white-page-generator/api/white-pages?filter[user_id]=${id}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const getDomainStats =
    (bayerId, page, search = '', profile_status) =>
    async (dispatch) => {
        try {
            const resp = await axios.get(
                `https://statistics.moar.tools/domains/domains/bayer-domains/${bayerId}?limit=15&page=${page}&search=${search}&profile_status=${profile_status}`,
            );
            dispatch({ type: SET_DOMAIN_STATS, payload: resp.data });
            return resp.data;
        } catch (error) {
            helperError(error);
        }
    };

export const deleteStatsDomain = (bayerId, domain) => async () => {
    try {
        const resp = await axios.delete(`https://statistics.moar.tools/domains/domains/bayer-domain/${bayerId}/${domain}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const changePagesStats = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE_STATS, payload: page });
};

export const checkDomainStats = (nameDomain) => async () => {
    try {
        const resp = await axios.get(`https://statistics.moar.tools/domains/domains/records/${nameDomain}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const postUuid = (options) => async () => {
    try {
        const resp = await axios.post(`https://statistics.moar.tools/domains/profiles/uuid-bayer/date-range`, options);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const postSynchrone = (domainName) => async () => {
    try {
        const resp = await axios.post(`https://statistics.moar.tools/domains/profiles/synchrone/${domainName}`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const getNoogleStatistic = (nameDomain, startDate, endDate) => async () => {
    try {
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
        const resp = await axios.get(
            `https://statistics.moar.tools/statistic?domain=${nameDomain}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&total=true`,
        );
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getNoogleStatisticGraphs = (nameDomain, startDate, endDate) => async () => {
    try {
        const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
        const resp = await axios.get(
            `https://statistics.moar.tools/statistic/graphs?domain=${nameDomain}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&total=true`,
        );
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const downloadRdp = () => async () => {
    try {
        const response = await API.get(`/rdp/download`, {
            responseType: 'arraybuffer',
        });
        return response;
    } catch (e) {
        helperError(e);
    }
};

export const getBinomCheck = () => async () => {
    try {
        const resp = await API.get(`/microservices/filtering/api/v0/user/binom/check`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getServerGeneralDomain = () => async () => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/general-domain-servers`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getLocations = () => async () => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/thank-you-pages/locales`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getListWhitePage = () => async () => {
    try {
        const resp = await API.get(`/microservices/white-page-generator/api/white-pages?filter[status]=processing,pending`);
        return resp.data.data;
    } catch (error) {
        helperError(error);
    }
};

export const getTypeSetting = () => async () => {
    try {
        const resp = await API.get(`/microservices/servers-managment/api/v1/domains/types`);
        return resp.data;
    } catch (error) {
        helperError(error);
    }
};

export const connectedGeneralDomain = (server) => async () => {
    try {
        const resp = await API.post(`/microservices/general-domain/api/v1/servers/${server}/upload`);
        NotificationManager.success(`Успешно`, '', 3500);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getRkCheck = (domainId) => async () => {
    try {
        const resp = await API.get(`/microservices/filtering/api/v0/domain/${domainId}/download/check`);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const binomConnect = (options) => async () => {
    try {
        const resp = await API.post(`/microservices/filtering/api/v0/user/binom/connect`, options);
        NotificationManager.success(`Binom успешно подключен`, '', 5000);

        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const paladiumCreate = (id, options) => async () => {
    try {
        const resp = await API.post(`/microservices/filtering/api/v0/domain/${id}/palladium/create`, options);
        NotificationManager.success(`Palladium успешно создан`, '', 5000);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const orderCreate = (options) => async () => {
    try {
        const resp = await API.post(`/microservices/servers-managment/api/v1/orders`, options);
        NotificationManager.success(`Заказ успешно создан`, '', 5000);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const binomCreate = (id, options) => async () => {
    try {
        const resp = await API.post(`/microservices/filtering/api/v0/domain/${id}/binom/create`, options);
        NotificationManager.success(`Binom успешно создан`, '', 5000);
        return resp;
    } catch (error) {
        helperError(error);
    }
};

export const getDomeinsBuyer = (page, name) => async (dispatch) => {
    try {
        const response = await API.get(
            `/microservices/servers-managment/api/v1/domains/?filter[status]=free&page=${page}&filter[name]=${name}`,
        );
        dispatch({ type: SET_DOMAIN_BUYER, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getDomeinsBuyerReserved = (page, name) => async (dispatch) => {
    try {
        const response = await API.get(
            `/microservices/servers-managment/api/v1/domains/?filter[status]=reserved,stop,ban,unban,in_progress,spend&page=${page}&filter[user_id]=${user?.id}&filter[name]=${name}`,
        );
        dispatch({ type: SET_DOMAIN_BUYER_RESERVED, payload: response.data });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getScripts = (type) => async () => {
    try {
        const response = await API.post(`/microservices/servers-managment/api/v1/go-push/names`, { type: type });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getPresets = (type) => async () => {
    try {
        const response = await API.get(`/microservices/servers-managment/api/v1/presets?filter[type]=${type}`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getVersionScript = (name, type) => async () => {
    try {
        const response = await API.post(`/microservices/servers-managment/api/v1/go-push/versions`, {
            script_name: name,
            type: type,
        });
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const connectScript = (build, domain) => async () => {
    let options = {
        script_name: build.script,
        script_folder: build.version,
        type: build.type,
    };
    if (build.preset) {
        options.preset_id = build.preset;
    }

    if (build?.type === 'DAO') {
        options.additional_preset_id = build?.additional_preset_id;
        options.source_id = build?.source_id;
    }

    const link = build.preset
        ? `/microservices/servers-managment/api/v1/go-push/create/${domain}/${build.preset}`
        : `/microservices/servers-managment/api/v1/go-push/create/${domain}`;

    try {
        const response = await API.post(`${link}`, options);
        NotificationManager.success(`Скрипт успешно подключен`, '', 3500);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const uploadWhitePage = (file, domain) => async () => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const resp = await API.post(`microservices/servers-managment/api/v1/domains/${domain}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        NotificationManager.success(`White page успешно загружен`, '', 3500);
        return resp;
    } catch (e) {
        helperError(e);
    }
};

export const changePages = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
};

export const changeTab = (tab) => async (dispatch) => {
    dispatch({ type: CHANGE_TAB_BUYER, payload: tab });
};

export const changePagesReserved = (page) => async (dispatch) => {
    dispatch({ type: CHANGE_PAGE_RESERVED, payload: page });
};

export const getStaticRk = (domainId) => async () => {
    try {
        const response = await API.get(`/microservices/filtering/api/v0/domain/${domainId}/palladium/static`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const getDataRk = (domainId) => async () => {
    try {
        const response = await API.get(`/microservices/filtering/api/v0/domain/${domainId}/palladium/data`);
        return response.data;
    } catch (e) {
        helperError(e);
    }
};

export const updateRk = (domainId, options) => async () => {
    try {
        const response = await API.put(`/microservices/filtering/api/v0/domain/${domainId}/palladium/update`, options);
        NotificationManager.success(`RK успешно изменен`, '', 5000);

        return response.data;
    } catch (e) {
        helperError(e);
    }
};
