import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginByEmail } from '../Login/actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../core/helpers/routes';
import { NotificationContainer } from 'react-notifications';
import docCookies from '../../core/services/cookie';
import { vaultService } from '../../core/services/vault-service';
import '../Login/Login.scss';
import Button from '../../components/NewUI/Button';
import Input from '../../components/NewUI/Input';

const LoginSSO = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const accessToken = docCookies.getItem('token');
    const navigate = useNavigate();

    const handleChangeEmail = (value) => {
        setEmail(value);
    };

    const handleChangePassword = (value) => {
        setPassword(value);
    };

    const service = vaultService();

    const logOut = () => {
        docCookies.removeItem('token');
        service.removeItems('role');
        service.removeItems('user');
    };

    const submitClickHandler = async () => {
        setIsLoading(true);
        const resp = await dispatch(loginByEmail(email, password));

        if (typeof resp.data?.message === 'string' || typeof resp.data === 'string') {
            setEmail('');
            setPassword('');
            logOut();
            setIsLoading(false);
            return;
        }

        if (resp?.response?.status === 422) {
            setErrorLogin(true);
            setTimeout(() => {
                setErrorLogin(false);
            }, 3000);
            setIsLoading(false);
            return;
        }

        if (resp?.data?.access_token) {
            const token = resp.data.access_token;

            const referrer = document.referrer;

            if (referrer) {
                const url = new URL(referrer);

                url.searchParams.set('token', token);

                window.location.href = url.toString();
            } else {
                navigate(ROUTES.index.main);
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if(accessToken) {
            const referrer = document.referrer;

            if (referrer) {
                const url = new URL(referrer);

                url.searchParams.set('token', accessToken);

                window.location.href = url.toString();
            } else {
                navigate(ROUTES.index.main);
            }
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            submitClickHandler();
        }
    };

    return (
        <div className="Login">
            <div className="Login-form">
                <div className="Login-form-title">Sign In</div>
                <div className="Login-form-border" />
                <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
                    <Input
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={handleChangeEmail}
                        onKeyDown={handleKeyDown}
                    />
                    <Input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handleChangePassword}
                        onKeyDown={handleKeyDown}
                    />
                </div>

                {errorLogin && (
                    <div className="d-flex text-danger">
                        <span>Не правильный логин или пароль</span>
                    </div>
                )}
                <Button className="Login-button" onClick={submitClickHandler} disabled={isLoading} isLoading={isLoading}>
                    Sign In
                </Button>
            </div>

            <NotificationContainer />
        </div>
    );
};

export default LoginSSO;
