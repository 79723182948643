export const ArrowDropSvg = (props) => {
    return (
        <div {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3 6C4.79735 8.19374 5.89329 9.2899 8 11C10.1067 9.2899 11.2026 8.19374 13 6"
                    stroke="#676C74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
