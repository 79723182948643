import './styles.scss';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { typeScript } from '../../../helpers/data';
import Input from '../../NewUI/Input';
import { createPreset } from '../../../pages/SysAdmin/action';
import { useDispatch } from 'react-redux';
import Button from '../../NewUI/Button';

const ModalCreatePreset = ({ show, closeModal }) => {
    const [build, setBuild] = useState({
        type: null,
        link1: '',
        link2: '',
        name: '',
        general_domain_link: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleChangeSelect = (item, key) => {
        setBuild({ ...build, [key]: item });
    };

    const handleChangeInput = (value, id) => {
        setBuild({ ...build, [id]: value });
    };

    const closedModal = () => {
        setBuild({
            type: null,
            link1: '',
            link2: '',
            name: '',
            general_domain_link: '',
        });
        closeModal();
    };

    const isDisableButton = () => {
        if (build.type === null) return true;
        if (build.name.length === 0) return true;
        if (build.link1.length === 0) return true;
        if (build.link2.length === 0) return true;
        if (build.general_domain_link.length === 0) return true;
        return false;
    };

    const handleClickCreatePreset = async () => {
        setIsLoading(true);
        const resp = await dispatch(createPreset(build));
        if (resp) {
            closedModal();
        }
        setIsLoading(false);
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-preset">
            <Modal.Header closeButton>
                <Modal.Title>Create preset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="Preset">
                    <Row>
                        <Col>
                            <Input
                                onChange={(e) => handleChangeSelect(e, 'type')}
                                placeholder={'Выберите Type'}
                                options={typeScript}
                                default={build?.type}
                                value={build?.type}
                            />
                        </Col>
                        <Col>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'name')}
                                type="text"
                                id="name"
                                placeholder={'Название'}
                                value={build?.name ? build?.name : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'link1')}
                                type="text"
                                id="link1"
                                placeholder={'Link 1'}
                                value={build?.link1 ? build?.link1 : ''}
                            />
                        </Col>
                        <Col>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'link2')}
                                type="text"
                                id="link2"
                                placeholder={'Link 2'}
                                value={build?.link2 ? build?.link2 : ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'general_domain_link')}
                                type="text"
                                id="general_domain_link"
                                placeholder={'General domain link'}
                                value={build?.general_domain_link ? build?.general_domain_link : ''}
                            />
                        </Col>
                    </Row>
                    <div className="Preset-button">
                        <Button buttonType="gray" onClick={closedModal}>
                            Отмена
                        </Button>
                        <Button disabled={isDisableButton()} onClick={handleClickCreatePreset} isLoading={isLoading}>
                            Создать
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreatePreset;
