export const FinanceSvg = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.7664 16.4798C5.95195 17.5335 6.79459 18.3802 7.85236 18.5407C8.90267 18.7 9.8511 18.8565 11.3077 18.8565C12.7644 18.8565 13.7128 18.7 14.7631 18.5407C15.8209 18.3802 16.6635 17.5335 16.8491 16.4798C16.9922 15.6669 17.1228 14.9609 17.1228 13.5952C17.1228 12.2296 16.9922 11.5236 16.8491 10.7106C16.6635 9.65696 15.8209 8.81029 14.7631 8.6498C13.7128 8.49045 12.7644 8.33398 11.3077 8.33398C9.8511 8.33398 8.90267 8.49045 7.85236 8.6498C6.79459 8.81029 5.95195 9.65696 5.7664 10.7106C5.62325 11.5236 5.49268 12.2296 5.49268 13.5952C5.49268 14.9609 5.62325 15.6669 5.7664 16.4798Z"
                    stroke="#111111"
                    strokeWidth="1.5"
                />
                <path
                    d="M17.5075 15.2018H14.077C13.1594 15.2018 12.4155 14.4579 12.4155 13.5404C12.4155 12.6228 13.1594 11.8789 14.077 11.8789H17.5075C18.0598 11.8789 18.5075 12.3266 18.5075 12.8789V14.2018C18.5075 14.7541 18.0598 15.2018 17.5075 15.2018Z"
                    stroke="#111111"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M7.18896 8.85L13.0668 5.30224C13.6339 4.95994 14.3726 5.18863 14.6471 5.79145L16.409 9.6602"
                    stroke="#111111"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </svg>
        </>
    );
};
