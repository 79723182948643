import { Col, Modal, Row } from 'react-bootstrap';
import './CreateOffer.scss';
import { useEffect, useState } from 'react';
import Input from '../../NewUI/Input';
import Button from '../../NewUI/Button';

const CreateOffer = ({ closeModal, show, setValues, values, edit }) => {
    const [build, setBuild] = useState({
        name: '',
        data: '',
        price: '',
    });

    const closedModal = () => {
        closeModal();
        setBuild({
            name: '',
            data: '',
            price: '',
        });
    };

    const createOffer = async () => {
        if (edit) {
            const newOffers = values.goods.map((item, index) => {
                if (index === edit.id) {
                    return build;
                }
                return item;
            });
            setValues({ ...values, goods: newOffers });
            closedModal();
        } else {
            setValues({ ...values, goods: [...values.goods, build] });
            closedModal();
        }
    };

    const handleChangeInput = (value, key) => {
        setBuild({ ...build, [key]: value });
    };

    const handleChangeInputNumber = (value, key) => {
        if (/^\d*$/.test(value)) {
            setBuild({ ...build, [key]: value });
        }
    };

    const isDisableButton = () => {
        if (build.name?.length === 0) return true;
        if (build.data?.length === 0) return true;
        if (build.price?.length === 0) return true;
        return false;
    };

    useEffect(() => {
        if (edit) {
            setBuild({
                name: edit.name,
                data: edit.data,
                price: edit.price,
            });
        }
    }, []);

    return (
        <Modal show={show} onHide={closedModal} className="modal-gd">
            <Modal.Header closeButton>
                <Modal.Title>{edit ? 'Edit' : 'Create'} offer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row className="d-flex flex-column gap-2">
                        <Col xs={12}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'name')}
                                type="text"
                                id="name"
                                placeholder="Name"
                                value={build?.name ? build?.name : ''}
                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'data')}
                                type="text"
                                id="description"
                                placeholder="Description"
                                value={build?.data ? build?.data : ''}
                            />
                        </Col>
                        <Col xs={12}>
                            <Input
                                onChange={(value) => handleChangeInputNumber(value, 'price')}
                                placeholder="Price"
                                value={build?.price ? build?.price : ''}
                            />
                        </Col>
                    </Row>
                    <div className="CreateOrder-buttons">
                        <Button disabled={isDisableButton()} onClick={createOffer}>
                            {edit ? 'Edit' : 'Create'}
                        </Button>
                        <Button buttonType="gray" variant="secondary" onClick={closedModal}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateOffer;
