import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import Input from '../../NewUI/Input';
import { useState } from 'react';
import { optionsGeo } from '../../../utils/data';
import { useDispatch } from 'react-redux';
import { createServer } from '../../../pages/SysAdmin/action';
import moment from 'moment';
import Calendar from '../../Calendar';
import Button from '../../NewUI/Button';

const ModalCreateServer = ({ closeModal, show, loadServer }) => {
    const [build, setBuild] = useState({
        ip: '',
        start_date: null,
        expiration_date: null,
        host_provider_name: '',
        geolocation: null,
        port: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const handleClickCreate = async () => {
        setIsLoading(true);
        const options = {
            ip: build.ip,
            start_date: moment(build.start_date).format('Y-MM-DD HH:mm:ss'),
            expiration_date: moment(build.expiration_date).format('Y-MM-DD HH:mm:ss'),
            host_provider_name: build.host_provider_name,
            geolocation: build.geolocation,
            port: build.port,
        };
        const resp = await dispatch(createServer(options));
        if (resp) {
            setBuild({
                ip: '',
                start_date: null,
                expiration_date: null,
                host_provider_name: '',
                geolocation: null,
                port: '',
            });
            setIsLoading(false);
            loadServer();
        }
        setIsLoading(false);
    };

    const closedModal = () => {
        setBuild({
            ip: '',
            start_date: null,
            expiration_date: null,
            host_provider_name: '',
            geolocation: null,
        });
        closeModal();
    };

    const handleChangeInput = (value, key) => {
        setBuild({ ...build, [key]: value });
    };

    const handleChangeDate = (key, date) => {
        setBuild({ ...build, [key]: date });
    };

    const handleChangeSelect = (value) => {
        setBuild({ ...build, geolocation: value });
    };

    const isDisableButton = () => {
        if (isLoading) return true;
        if (build.expiration_date === null) return true;
        if (build.ip.length === 0) return true;
        if (build.start_date === null) return true;
        if (build.host_provider_name.length === 0) return true;
        if (build.geolocation === null) return true;
        return false;
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-server">
            <Modal.Header closeButton>
                <Modal.Title>Добавить сервер</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="Server">
                    <Row>
                        <Col xs={6}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'ip')}
                                type="text"
                                id="ip"
                                placeholder={'IP Server'}
                                value={build?.ip ? build?.ip : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <Calendar
                                value={build.start_date}
                                placeholder="Дата покупки"
                                onChange={(e) => handleChangeDate('start_date', e)}
                                positionDrop="top"
                                resettable={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'host_provider_name')}
                                type="text"
                                id="host_provider_name"
                                placeholder={'Name Hosting Provider'}
                                value={build?.host_provider_name ? build?.host_provider_name : ''}
                            />
                        </Col>
                        <Col xs={6}>
                            <Calendar
                                value={build.expiration_date}
                                placeholder="Срок дейсвия"
                                onChange={(e) => handleChangeDate('expiration_date', e)}
                                positionDrop="top"
                                resettable={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Input
                                onChange={handleChangeSelect}
                                placeholder={'Geo'}
                                options={optionsGeo}
                                itemLabel={'label'}
                                itemValue={'value'}
                                default={build?.geolocation}
                                value={build?.geolocation}
                            />
                        </Col>
                        <Col xs={6}>
                            <Input
                                onChange={(value) => handleChangeInput(value, 'port')}
                                type="text"
                                id="port"
                                placeholder={'Port'}
                                value={build?.port ? build?.port : ''}
                            />
                        </Col>
                    </Row>
                    <div className="Server-buttons">
                        <Button buttonType="gray" onClick={closedModal}>
                            Отмена
                        </Button>
                        <Button disabled={isDisableButton()} onClick={handleClickCreate} isLoading={isLoading}>
                            Добавить
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCreateServer;
