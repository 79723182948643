import { vaultService } from '../../core/services/vault-service';
import Header from './header';
import Menu from './menu';
import './styles.scss';
import NavigationMenu from './menu';
import { useState } from 'react';

const Template = ({ children }) => {
    const service = vaultService();
    const role = service.getItem('role');
    const [isHiddenNavigationMenu, setIsHiddenNavigationMenu] = useState(false);

    const isMenu = role === 'buyer' || role === 'webmaster' || role === 'sysadmin';
    return (
        <div className="wrapper_template">
            {isMenu && (
                <NavigationMenu
                    isHiddenNavigationMenu={isHiddenNavigationMenu}
                    setIsHiddenNavigationMenu={setIsHiddenNavigationMenu}
                />
            )}

            <div
                className="wrapper_content"
                style={isMenu ? (!isHiddenNavigationMenu ? { width: 'calc(100% - 240px)' } : { width: 'calc(100% - 72px)' }) : {}}
            >
                <Header />
                <div className="wrapper_children">{children}</div>
            </div>
        </div>
    );
};

export default Template;
